






































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import appointmentStoreModule from "@/store/modules/appointment";
import employeeStoreModule from "@/store/modules/employee";
import { Business, Employee, Role } from "@/types";

const { mapActions: appointmentActions, mapGetters: appointmentGetters } =
  createNamespacedHelpers("CLIENT_APPOINTMENTS");

const { mapActions: employeeActions, mapGetters: employeeGetters } =
  createNamespacedHelpers("SCHEDULE_EMPLOYEE_LIST");

export default Vue.extend<any, any, any, any>({
  name: "ClientGraph",
  data: () => ({
    date: "Today",
    selectedEmployees: [] as Employee[],
  }),
  created() {
    this.fetchAppointments();
  },
  watch: {
    role: "fetchAppointments",
    date: "fetchAppointments",
    selectedEmployees: "fetchAppointments",
  },
  computed: {
    ...appointmentGetters(["appointmentPage"]),
    ...employeeGetters(["employeePage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  filters: {
    firstName(value: string) {
      return value.split(" ")[0];
    },
  },
  methods: {
    ...appointmentActions(["fetchAppointmentList"]),
    ...employeeActions(["fetchEmployeeList"]),
    fetchAppointments() {
      if (this.role) {
        // let period: any = "day";
        // switch (this.date) {
        //   case "This Year":
        //     period = "year";
        //     break;
        //   case "This Month":
        //     period = "month";
        //     break;
        //   case "This Week":
        //     period = "week";
        //     break;
        //   case "Today":
        //     period = "day";
        //     break;
        //   case "All":
        //     period = "all";
        //     break;
        // }
        // const startDate = moment().startOf(period).toISOString();
        // const endDate = moment().endOf(period).toISOString();
        const bid = (this.role.business as Business)?._id;
        let params = `?businessId=${bid}&limit=5&status=confirmed&sort=-startDate`;
        // if (period !== "all") {
        //   params += `&startDate=${startDate}&endDate=${endDate}`;
        // }
        const eid = this.selectedEmployees
          .map((e) => e._id)
          .join("&employeeId=");
        if (eid) {
          params += `&employeeId=${eid}`;
        }
        this.fetchAppointmentList(params);
      }
    },
    searchEmployees(q: string) {
      const bid = (this.role.business as Business)._id;
      let params = `?businessId=${bid}`;
      if (q) {
        params += `&q=${q}`;
      }
      this.fetchEmployeeList(params);
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("CLIENT_APPOINTMENTS")) {
      this.$store.registerModule("CLIENT_APPOINTMENTS", appointmentStoreModule);
    }
    if (!this.$store.hasModule("SCHEDULE_EMPLOYEE_LIST")) {
      this.$store.registerModule("SCHEDULE_EMPLOYEE_LIST", employeeStoreModule);
    }
  },
  beforeDestroy() {
    // this.$store.unregisterModule("CLIENT_APPOINTMENTS");
    this.$store.unregisterModule("SCHEDULE_EMPLOYEE_LIST");
  },
});
