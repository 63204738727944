












































































































































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import { Appointment, Business, Role } from "@/types";
import appointmentStoreModule from "@/store/modules/appointment";
import moment from "moment-timezone";
const tz = localStorage.getItem("tz") ?? "Africa/Nairobi";

const { mapActions: appointmentActions, mapGetters: appointmentGetters } =
  createNamespacedHelpers("APPOINTMENT_LIST");

export default Vue.extend<any, any, any, any>({
  name: "AppointmentList",
  data: () => ({
    appointment: undefined,
    showDialog: false,
    showNotesDialog: false,
    editingNotes: false,
    notes: "",
    headers: [
      { text: "Client", value: "client" },
      {
        text: "Service(s)",
        align: "start",
        sortable: true,
        value: "services",
      },
      { text: "Date", value: "date" },
      { text: "Time", value: "time" },
      { text: "Staff", value: "employee" },
      { text: "Appointment Status", value: "status" },
      { text: "Payment Status", value: "paymentStatus" },
      { text: "Action", value: "action" },
    ],
    options: {} as { page: number },
    status: "completed",
  }),
  created() {
    this.fetchAppointments();
  },
  watch: {
    options: {
      handler() {
        this.fetchAppointments();
      },
      deep: true,
    },
  },
  computed: {
    ...appointmentGetters(["appointmentPage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...appointmentActions([
      "fetchAppointmentList",
      "updateAppointment",
      "deleteAppointment",
    ]),
    saveNotes(id: string) {
      this.updateAppointment({ id, appointment: { notes: this.notes } });
    },
    fetchAppointments() {
      if (this.role) {
        const bid = (this.role.business as Business)._id;
        const page = this.options.page || 1;
        const date = moment().startOf("day").toISOString();
        const params = `?businessId=${bid}&page=${page}&status=${this.status}&dateStart=${date}`;
        this.fetchAppointmentList(params);
      }
    },
    getEndDate(appointment: Appointment) {
      return moment(appointment.startDate)
        .add(appointment.durationInMinutes, "minutes")
        .toISOString();
    },
  },
  beforeCreate() {
    if (this.$store.hasModule("APPOINTMENT_LIST")) {
      this.$store.unregisterModule("APPOINTMENT_LIST");
    }
    this.$store.registerModule("APPOINTMENT_LIST", appointmentStoreModule);
  },
});
