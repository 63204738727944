

















































































import Vue, { VueConstructor } from "vue";
import { createNamespacedHelpers } from "vuex";

import { Role, Service } from "@/types";
import serviceStoreModule from "@/store/modules/service";
import EmployeeServiceForm from "@/components/service/EmployeeSerivceForm.vue";

const VUE_APP_API_URL = process.env.VUE_APP_API_URL;

interface ServiceListClass {
  fetchServiceList(params: string): void;
  editService(service: Service): Service;
}

export default (Vue as VueConstructor<Vue & ServiceListClass>).extend<
  any,
  any,
  any,
  any
>({
  name: "EmployeeServiceList",
  props: {
    employeeId: {
      type: String,
    },
  },
  components: {
    EmployeeServiceForm,
  },
  data: () => ({
    service: undefined,
    showUpdateDialog: false,
    apiUrl: VUE_APP_API_URL,
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "name",
      },
      { text: "Duration", value: "durationInMinutes" },
      { text: "Action", value: "action" },
    ],
    options: {} as { page: number },
    services: [],
    addServiceDialog: false,
  }),
  watch: {
    options: {
      handler() {
        this.fetchServices();
      },
      deep: true,
    },
  },
  created() {
    this.fetchServices();
  },
  computed: {
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    fetchServices() {
      if (this.employeeId) {
        const page = this.options.page || 1;
        const limit = this.options.itemsPerPage || 10;
        const params = `?employeeId=${this.employeeId}&page=${page}&limit=${limit}`;
        this.fetchServiceList(params);
      }
    },
    removeService() {
      const performedBy = this.service.performedBy
        .filter((item) => item._id != this.employeeId)
        .map((item) => item._id);
      this.updateService({
        id: this.service._id,
        service: {
          performedBy,
        },
      }).then((s) => {
        if (s) {
          this.fetchServices();
          this.showUpdateDialog = false;
        }
      });
    },
    saved(data: any) {
      if (data) {
        this.fetchServices();
      }
      this.addServiceDialog = false;
    },
  },
  beforeCreate() {
    const namespace = `EMPLOYEE_SERVICE_LIST`;
    if (!this.$store.hasModule(namespace)) {
      this.$store.registerModule(namespace, serviceStoreModule);
    }
    const { mapGetters, mapActions } = createNamespacedHelpers(namespace);

    this.$options.computed = {
      ...(this.$options.computed || {}),
      ...mapGetters(["servicePage"]),
    };

    this.$options.methods = {
      ...(this.$options.methods || {}),
      ...mapActions(["fetchServiceList", "deleteService", "updateService"]),
    };
  },
  beforeDestroy() {
    const namespace = `EMPLOYEE_SERVICE_LIST`;
    this.$store.unregisterModule(namespace);
  },
});
