





























































































import { Role, User } from "@/types";
import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import paystackStoreModule from "@/store/modules/paystack";

const namesapce = "PAYSTACK_GATEWAY";
const { mapActions: paystackActions, mapGetters: paystackGetters } =
  createNamespacedHelpers(namesapce);

export default Vue.extend({
  name: "PaystackVendorForm",
  data: () => ({
    accountNumber: "",
    bankCode: "",
    type: "",
    loading: false,
    sendingOtp: false,
    verificationCode: "",
  }),
  computed: {
    ...paystackGetters(["banks", "getTransferRecipient"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  watch: {
    role() {
      if (this.role) {
        const params = `?businessId=${this.role.business._id}`;
        this.fetchTransferRecipient(params);
      }
    },
  },
  created() {
    this.fetchBanks("?currency=KES");
    if (this.role) {
      this.loading = true;
      const params = `?businessId=${this.role.business._id}`;
      this.fetchTransferRecipient(params).then(() => {
        this.loading = false;
      });
    }
  },
  methods: {
    ...paystackActions([
      "createTransferRecipient",
      "fetchBanks",
      "fetchTransferRecipient",
      "updateTransferRecipient",
      "deleteTransferRecipient",
      "sendRecipientCode",
    ]),
    getRules(field: string) {
      return [(v: string) => !!v || `${field} is required`];
    },
    save() {
      const valid = (
        this.$refs.form as Element & { validate: () => boolean }
      )?.validate();

      if (!valid) {
        return;
      }

      if (!this.getTransferRecipient) {
        let payload = {
          businessId: this.role.business._id,
          userId: (this.role.user as User)._id,
          accountNumber: this.accountNumber,
          bankCode: this.bankCode,
          type:
            this.bankCode === "MPESA" ||
            this.bankCode === "MPTILL" ||
            this.bankCode === "MPPAYBILL"
              ? "mobile_money"
              : "kepss",
          // authorizationCode: "",
          verificationCode: this.verificationCode,
        };
        this.createTransferRecipient(payload).then((recipient) => {
          if (recipient) this.$emit("saved", recipient);
        });
      }
    },
    close() {
      this.$emit("close-dialog", true);
    },
    deleteAccount() {
      const id = this.getTransferRecipient._id;
      this.deleteTransferRecipient(`/${id}`).then(() => {
        this.$emit("saved", null);
      });
    },
    sendCode() {
      this.sendRecipientCode({ businessId: this.role.business._id });
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule(namesapce)) {
      this.$store.registerModule(namesapce, paystackStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule(namesapce);
  },
});
