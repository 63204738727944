














































import Vue from "vue";

import AdList from "@/components/marketing/AdList.vue";
import DiscountList from "@/components/marketing/DiscountList.vue";
import Vouchers from "./Vouchers.vue";
import CampaignList from "@/components/marketing/CampaignList.vue";
import Email from "@/components/marketing/Email.vue";
import SMSList from "@/components/marketing/SMSList.vue";
import AddOnForm from "@/components/AddOnForm.vue";
import { createNamespacedHelpers } from "vuex";

import vendorPlanStoreModule from "@/store/modules/vendorPlan";
import { Role } from "@/types";
import AdvertMarketing from "./AdvertMarketing.vue";
import paystackStoreModule from "@/store/modules/paystack";

import { Business, VendorPlan } from "@/types";
import moment from "moment";

const { mapActions: vendorPlanActions } =
  createNamespacedHelpers("VENDOR_PLAN");

const { mapActions: paystackActions } = createNamespacedHelpers(
  "PAYSTACK_SUBSCRIPTION_ADDON"
);

export default Vue.extend({
  components: {
    Vouchers,
    DiscountList,
    AdList,
    CampaignList,
    Email,
    "sms-list": SMSList,
    AddOnForm,
    AdvertMarketing,
  },
  name: "Marketing",
  data: () => ({
    modal: false,
    advert: true,
    loading: false,
    showInitDialog: false,
    authUrl: "",
    authorizationCode: "",
  }),
  computed: {
    role(): Role {
      return this.$store.getters.role;
    },
    vendorPlan(): VendorPlan {
      return JSON.parse(localStorage.getItem("plan") as string);
    },
    isSubscribed() {
      if (
        !this.$store.getters.role.business.vendorPlan.addOns
          ?.lastMarketingPaymentDate
      )
        return false;
      return (
        moment().diff(
          this.$store.getters.role.business.vendorPlan.addOns
            ?.lastMarketingPaymentDate,
          "months"
        ) <= 1
      );
    },
  },
  methods: {
    ...vendorPlanActions(["updateVendorPlan"]),
    ...paystackActions(["initPlanTransaction", "subscribeCustomer"]),
    async initSubsription() {
      this.loading = true;
      const roleId = localStorage.getItem("roleId") as string;
      const data = await this.$store.dispatch("getLoggedInUser");
      const vendorPlan: VendorPlan = data?.roles.find(
        (role) => role._id === roleId
      )?.business.vendorPlan;

      if (vendorPlan?.paystackAuthorizationCode) {
        this.authorizationCode = vendorPlan.paystackAuthorizationCode;
        this.subscribe();
        return;
      }
      this.initPlanTransaction({
        //email: (this.role.user as User).email,
        reference: this.vendorPlan._id,
        subscriptionType: "marketing",
        amount: 100,
      }).then((url: string) => {
        this.loading = false;
        if (url) {
          this.authUrl = url;
          this.showInitDialog = true;
          this.checkSubscription();
        }
      });
    },

    async checkSubscription() {
      const roleId = localStorage.getItem("roleId") as string;
      const data = await this.$store.dispatch("getLoggedInUser");
      const vendorPlan: VendorPlan = data?.roles.find(
        (role) => role._id === roleId
      )?.business.vendorPlan;

      if (vendorPlan?.paystackAuthorizationCode) {
        this.authorizationCode = vendorPlan.paystackAuthorizationCode;
        this.subscribe();
      } else {
        setTimeout(() => {
          this.checkSubscription();
        }, 2500);
      }
    },
    subscribe() {
      this.loading = true;
      this.subscribeCustomer({
        businessId:
          (this.vendorPlan.business as Business)._id ??
          this.vendorPlan.business,
        planId: this.vendorPlan.plan._id,
        authorizationCode: this.authorizationCode,
        addOn: "marketing",
      }).then((subcription) => {
        this.loading = false;
        if (subcription) {
          this.$router.replace((this.$route.query.redirect as string) ?? "/");
        }
      });
    },
    buy() {
      this.initSubsription();
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("VENDOR_PLAN")) {
      this.$store.registerModule("VENDOR_PLAN", vendorPlanStoreModule);
    }

    if (!this.$store.hasModule("PAYSTACK_SUBSCRIPTION_ADDON")) {
      this.$store.registerModule(
        "PAYSTACK_SUBSCRIPTION_ADDON",
        paystackStoreModule
      );
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("VENDOR_PLAN");
    // this.$store.unregisterModule("PAYSTACK_SUBSCRIPTION");
  },
});
