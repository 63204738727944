































import Vue from "vue";
import { VueConstructor } from "vue/types/umd";

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: {
        imageField: HTMLInputElement;
      };
    }
  >
).extend<any, any, any, any>({
  name: "FormBackgroundImage",
  props: {
    title: {
      type: String,
    },
  },
  data: () => ({
    rules: [
      (value: File) => !!value || "Image field is required",
      (value: File) =>
        !value || value.size / 1e6 < 3 || "Maximum allowed Image size is 3MB!",
    ],
    image: undefined as undefined | File,
  }),
  mounted() {
    this.image = undefined;
    (
      this.$refs.uploadForm as Element & {
        resetValidation: () => void;
      }
    )?.resetValidation();
  },
  methods: {
    async handleUpload(file: File) {
      try {
        const result = await this.validateFile(file);
        this.image = file;
        console.log(result); // "File is valid."
      } catch (error) {
        alert(error);
        console.error(error); // Validation error message
      }
    },
    validateFile(file) {
      return new Promise((resolve, reject) => {
        const maxSize = 500 * 1024 * 3; // 500KB in bytes

        if (file.size > maxSize) {
          reject("Image could not be set, File size exceeds 3MB.");
          return;
        }

        const img = new Image();
        const objectUrl = URL.createObjectURL(file);

        img.onload = () => {
          const { width, height } = img;

          if (width !== 700 || height !== 500) {
            reject(
              `Image dimensions must be 700x500px. Current dimensions: ${width}x${height}px.`
            );
          } else {
            resolve("File is valid.");
          }

          URL.revokeObjectURL(objectUrl); // Clean up object URL
        };

        img.onerror = () => {
          reject("Invalid image file.");
          URL.revokeObjectURL(objectUrl);
        };

        img.src = objectUrl; // Start loading the image
      });
    },
    upload() {
      const valid = (
        this.$refs.uploadForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;

      this.$emit("data-valid", this.image);
    },
  },
});
