

























import Vue, { PropType } from "vue";
import { createNamespacedHelpers } from "vuex";
import moment from "moment-timezone";

import BarChart from "../chart/BarChart.vue";

import paymentStoreModule from "@/store/modules/payment";
import { Business, Role } from "@/types";
import { paddNumber } from "@/util/helpers";

const { mapActions: paymentActions, mapGetters: paymentGetters } =
  createNamespacedHelpers("REVENUE_AGGREGATE_");

const { mapActions: prevPaymentActions, mapGetters: prevPaymentGetters } =
  createNamespacedHelpers("PREV_REVENUE_AGGREGATE_");
export default Vue.extend({
  components: { BarChart },
  name: "RevenueGraph",
  props: {
    periodOverride: {
      type: String,
      required: false,
    },
    dateOverride: {
      type: Object as PropType<{
        label: string;
        start: string;
        end: string;
        period: string;
      }>,
      required: false,
    },
  },
  data: () => ({
    period: "Day",
    days: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    months: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    years: [
      2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033,
      2034, 2035,
    ],
    chartData: {
      labels: [] as string[] | number[],
      datasets: [
        {
          label: "This week",
          backgroundColor: "#F48203",
          data: [] as number[],
          barThickness: 15, // number (pixels) or 'flex'
          maxBarThickness: 15, // number (pixels)
          pointRadius: 0,
        },
        {
          label: "Previous week",
          borderColor: "#DFE0EA",
          data: [] as number[],
          barThickness: 15, // number (pixels) or 'flex'
          maxBarThickness: 15, // number (pixels)
          pointRadius: 0,
        },
      ],
    },
    chartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: true,
      },
      tooltips: {
        titleFontSize: 12,
        bodyFontSize: 12,
        callbacks: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          label(tooltipItem: any) {
            // Get the dataset label.
            // const label = data.datasets[tooltipItem.datasetIndex].label;

            // Format the y-axis value.
            const value = tooltipItem.yLabel;

            return `Kshs${value}`;
          },
        },
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              callback: (value: number) => {
                if (value / 1000 > 1) return value / 1000 + "k";
                return value;
              },
            },
            position: "right",
          },
        ],
      },
    },
    overridePeriod: false,
    weeks: Array.from({ length: 52 }, (_, i) => paddNumber(i + 1)),
  }),
  watch: {
    period() {
      if (!this.overridePeriod) this.getAggregate();
    },
    periodOverride() {
      if (this.periodOverride) this.period = this.periodOverride;
    },
    dateOverride() {
      if (this.dateOverride.start) {
        this.overridePeriod = true;
        this.period = this.dateOverride.period;

        if (!this.role) return;
        const businessId = (this.role.business as Business)._id;

        const { start, end, period } = this.dateOverride;
        let params = `/aggregate?businessId=${businessId}`;
        //prevPeriodParams = params;
        if (period !== "All")
          params += `&startDate=${start}&endDate=${end}&period=${period.toLowerCase()}`;
        this.fetchPaymentAggregate(params).then((agg) => {
          if (agg) {
            this.setChartData(period.toLowerCase());
          }
          this.overridePeriod = false;
        });
        return;
      }
    },
  },
  computed: {
    ...paymentGetters(["paymentAggregate"]),
    ...prevPaymentGetters({ prevPaymentAggregate: "paymentAggregate" }),
    role(): Role {
      return this.$store.getters.role;
    },
    periodTitle(): string {
      if (this.period === "All") return "Revenue Over the Years";
      let title = `Revenue by ${this.period}`;
      return title;
    },
  },
  created() {
    if (this.role) this.getAggregate();
  },
  methods: {
    ...paymentActions(["fetchPaymentAggregate"]),
    ...prevPaymentActions({
      fetchPrevPaymentAggregate: "fetchPaymentAggregate",
    }),
    getAggregate() {
      let prevPeriodParams = "";
      const businessId = (this.role.business as Business)._id;
      let params = "";
      let period = this.period.toLowerCase() as
        | "year"
        | "month"
        | "week"
        | "day"
        | "all";

      if (period === "all") {
        period = "year";
      }
      if (period === "year") {
        params = `?period=${period}`;
        prevPeriodParams = params;
      } else if (period === "month") {
        const startDate = moment().startOf("year").toISOString();
        const endDate = moment().endOf("year").toISOString();
        params = `?startDate=${startDate}&endDate=${endDate}&period=${period}`;

        const prevStartDate = moment()
          .subtract(1, "year")
          .startOf("year")
          .toISOString();
        const prevEndDate = moment()
          .subtract(1, "year")
          .endOf("year")
          .toISOString();

        prevPeriodParams = `?startDate=${prevStartDate}&endDate=${prevEndDate}&period=${period}`;
      } else if (period === "week") {
        let startDate = moment().startOf("year").toISOString();
        let endDate = moment().endOf("year").toISOString();
        params = `?startDate=${startDate}&endDate=${endDate}&period=${period}`;

        startDate = moment().subtract(1, "year").startOf("year").toISOString();
        endDate = moment().subtract(1, "year").endOf("year").toISOString();
        prevPeriodParams = `?startDate=${startDate}&endDate=${endDate}&period=${period}`;
      } else if (period === "day") {
        const startDate = moment().startOf("week").toISOString();
        const endDate = moment().endOf("week").toISOString();
        params = `?startDate=${startDate}&endDate=${endDate}&period=${period}`;

        const prevStartDate = moment()
          .subtract(1, "week")
          .startOf("week")
          .toISOString();
        const prevEndDate = moment()
          .subtract(1, "week")
          .endOf("week")
          .toISOString();

        prevPeriodParams = `?startDate=${prevStartDate}&endDate=${prevEndDate}&period=${period}`;
      }
      if (params === "") params = `?businessId=${businessId}&period=${period}`;
      else params += `&businessId=${businessId}`;

      this.fetchPaymentAggregate("/aggregate" + params).then((aggregate) => {
        if (aggregate) {
          if (prevPeriodParams === "")
            prevPeriodParams = `?businessId=${businessId}&period=${period}`;
          else prevPeriodParams += `&businessId=${businessId}`;
          this.fetchPrevPaymentAggregate("/aggregate" + prevPeriodParams).then(
            (prevAggregate) => {
              if (prevAggregate) this.setChartData(period);
            }
          );
        }
      });
    },
    setChartData(period: string) {
      if (period === "day") {
        const data = {
          Sun: 0,
          Mon: 0,
          Tue: 0,
          Wed: 0,
          Thu: 0,
          Fri: 0,
          Sat: 0,
        };

        this.paymentAggregate.map((a: { _id: number; amount: number }) => {
          const indx = this.days[a._id - 1] as "Sun" | "Mon";
          data[indx] = a.amount;
        });

        const prevPeriodData = {
          Sun: 0,
          Mon: 0,
          Tue: 0,
          Wed: 0,
          Thu: 0,
          Fri: 0,
          Sat: 0,
        };

        this.prevPaymentAggregate.map((a: { _id: number; amount: number }) => {
          const indx = this.days[a._id - 1] as "Sun" | "Mon";
          prevPeriodData[indx] = a.amount;
        });

        this.chartData = {
          labels: this.days,
          datasets: [
            {
              label: "Today",
              backgroundColor: "#F48203",
              data: Object.values(data),
              barThickness: 15, // number (pixels) or 'flex'
              maxBarThickness: 15, // number (pixels)
              pointRadius: 0,
            },
            {
              label: "Yesterday",
              backgroundColor: "#252733",
              data: Object.values(prevPeriodData),
              barThickness: 15, // number (pixels) or 'flex'
              maxBarThickness: 15, // number (pixels)
              pointRadius: 0,
            },
          ],
        };
        return;
      }

      if (period === "week") {
        const data = {};

        const prevPeriodData = {};

        this.weeks.map((w) => {
          data[w] = prevPeriodData[w] = 0;
        });

        this.paymentAggregate.map((a: { _id: number; amount: number }) => {
          data[a._id] = a.amount;
        });

        this.prevPaymentAggregate.map((a: { _id: number; amount: number }) => {
          prevPeriodData[a._id] = a.amount;
        });

        this.chartData = {
          labels: this.weeks,
          datasets: [
            {
              label: "Current Month",
              backgroundColor: "#F48203",
              data: Object.values(data),
              barThickness: 6, // number (pixels) or 'flex'
              maxBarThickness: 8, // number (pixels)
              pointRadius: 0,
            },
            {
              label: "Previous Month",
              backgroundColor: "#252733",
              data: Object.values(prevPeriodData),
              barThickness: 6, // number (pixels) or 'flex'
              maxBarThickness: 8, // number (pixels)
              pointRadius: 0,
            },
          ],
        };
        return;
      }

      if (period === "month") {
        const data = {
          Jan: 0,
          Feb: 0,
          Mar: 0,
          Apr: 0,
          May: 0,
          Jun: 0,
          Jul: 0,
          Aug: 0,
          Sep: 0,
          Oct: 0,
          Nov: 0,
          Dec: 0,
        };

        this.paymentAggregate.map((a: { _id: number; amount: number }) => {
          const indx = this.months[+a._id - 1] as "Jan" | "Feb";
          data[indx] = a.amount;
        });

        const prevPeriodData = {
          Jan: 0,
          Feb: 0,
          Mar: 0,
          Apr: 0,
          May: 0,
          Jun: 0,
          Jul: 0,
          Aug: 0,
          Sep: 0,
          Oct: 0,
          Nov: 0,
          Dec: 0,
        };

        this.prevPaymentAggregate.map((a: { _id: number; amount: number }) => {
          const indx = this.months[+a._id - 1] as "Jan" | "Feb";
          prevPeriodData[indx] = a.amount;
        });

        this.chartData = {
          labels: Object.keys(data),
          datasets: [
            {
              label: "Current year",
              backgroundColor: "#F48203",
              data: Object.values(data),
              barThickness: 15, // number (pixels) or 'flex'
              maxBarThickness: 15, // number (pixels)
              pointRadius: 0,
            },
            {
              label: "Previous year",
              backgroundColor: "#252733",
              data: Object.values(prevPeriodData),
              barThickness: 15, // number (pixels) or 'flex'
              maxBarThickness: 15, // number (pixels)
              pointRadius: 0,
            },
          ],
        };
        return;
      }

      if (period === "year" || period === "all") {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const data: any = {};

        const labels = this.years.filter((y) => new Date().getFullYear() >= +y);

        labels.map((l) => {
          data[l] = 0;
        });

        this.paymentAggregate.map((a: { _id: string; amount: number }) => {
          data[a._id] = a.amount;
        });

        this.chartData = {
          labels: labels,
          datasets: [
            {
              label: "Over the Years",
              backgroundColor: "#F48203",
              data: Object.values(data),
              barThickness: 15, // number (pixels) or 'flex'
              maxBarThickness: 15, // number (pixels)
              pointRadius: 0,
            },
          ],
        };
        return;
      }
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("REVENUE_AGGREGATE_")) {
      this.$store.registerModule("REVENUE_AGGREGATE_", paymentStoreModule);
    }
    if (!this.$store.hasModule("PREV_REVENUE_AGGREGATE_")) {
      this.$store.registerModule("PREV_REVENUE_AGGREGATE_", paymentStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("REVENUE_AGGREGATE_");
    this.$store.unregisterModule("PREV_REVENUE_AGGREGATE_");
  },
});
