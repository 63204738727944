















































import Vue from "vue";
import { api } from "@/util/axios";
import moment from "moment-timezone";
const tz = localStorage.getItem("tz") ?? "Africa/Nairobi";
export default Vue.extend<any, any, any, any>({
  name: "SalesReportList",
  data: () => ({
    headers: [
      { text: "Type", value: "report_name", sortable: false },
      { text: "Sales", value: "value", sortable: false },
      { text: "actions", value: "actions", sortable: false },
    ],
    reports: [
      { report_name: "All Sales", value: 0 },
      { report_name: "Product Sales", value: 0 },
      { report_name: "Service Sales", value: 0 },
    ],
    startDate: null,
    endDate: null,
  }),
  methods: {
    fetchSalesReports() {
      const businessId = this.$store.getters.role.business._id;

      let apiUrl = `/v1/sales-ledger/sales-report?businessId=${businessId}`;
      if (this.startDate) {
        const date = new Date(this.startDate);
        date.setHours(0, 0, 0, 0);
        apiUrl += `&startDate=${date.toISOString()}`;
      }
      if (this.endDate) {
        const date = new Date(this.endDate);
        date.setHours(23, 59, 59, 59);
        apiUrl += `&endDate=${date.toISOString()}`;
      }
      api.get(apiUrl).then((response) => {
        this.reports = response.data.reports.map((x) => {
          x.report_name = x.report_type;
          return x;
        });
      });
    },
    formatDate(date: string) {
      if (!date) return null;

      return moment(date).tz(tz).format("DD/MM/YYYY");
    },
    clear() {
      this.startDate = null;
      this.endDate = null;
    },
    navigate(item) {
      const query: any = {
        tab: "orders",
        type: item.report_name.toLowerCase().slice(0, -1),
      };
      if (this.startDate) {
        const date = new Date(this.startDate);
        date.setHours(0, 0, 0, 0);
        query.startDate = date.toISOString();
      }
      if (this.endDate) {
        const date = new Date(this.endDate);
        date.setHours(23, 59, 59, 59);
        query.endDate = date.toISOString();
      }
      this.$router.push({
        path: "/products",
        query,
      });
    },
  },
  watch: {
    startDate() {
      this.fetchSalesReports();
    },
    endDate() {
      this.fetchSalesReports();
    },
  },
  mounted() {
    console.log("mounted");
    this.fetchSalesReports();
  },
});
