















































































































import Vue, { VueConstructor } from "vue";
import { createNamespacedHelpers } from "vuex";

import clientStoreModule from "@/store/modules/client";
import userStoreModule from "@/store/modules/user";
import locationStoreModule from "@/store/modules/location";
import clientEmailStoreModule from "@/store/modules/clientEmail";
import { Business, Role, User, _Location } from "@/types";
import { PHONE_REGEX } from "@/util/constants";
import { getAuthToken, setAuth } from "@/util/auth";

const { mapActions: clientActions } = createNamespacedHelpers("CLIENT_FORM");

const { mapActions: userActions } = createNamespacedHelpers("USER");

const { mapActions: locationActions } =
  createNamespacedHelpers("CLIENT_LOCATIONS");

const { mapActions: emailActions } = createNamespacedHelpers("CLIENT_EMAIL");

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: {
        imageField: HTMLInputElement;
      };
    }
  >
).extend<any, any, any, any>({
  name: "ClientForm",
  data() {
    return {
      valid: false,
      fullName: "",
      phone: "",
      email: "",
      dob: "",
      gender: "",
      notes: "",
      address: "",
      fullNameRules: [(v: string) => !!v || "Full Name is required"],
      phoneRules: [
        /*(v: string) => !!v || "Phone is required" */
        (v: string) => PHONE_REGEX.test(v) || "Phone number must be valid",
      ],
      emailRules: [
        (v: string) => !!v || "Email is required",
        (v: string) => /.+@.+/.test(v) || "Email must be valid",
      ],
      // dobRules: [(v: string) => !!v || "Date of Birth is required"],
      genderRules: [(v: string) => !!v || "Gender is required"],
      addressRules: [],
      locations: [] as string[],
      image: undefined as undefined | File,
      country: "Kenya",
    };
  },
  mounted() {
    this.resetForm();
  },
  computed: {
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...clientActions(["createClient"]),
    ...userActions(["fetchUser"]),
    ...locationActions(["fetchLocationList"]),
    ...emailActions(["createClientEmail"]),
    addClient() {
      const formData = new FormData();
      formData.append("businessId", (this.role.business as Business)._id);
      formData.append("fullName", this.fullName);
      formData.append("phone", this.phone);
      formData.append("email", this.email);
      formData.append("notes", this.notes);
      formData.append("address", this.address);
      formData.append("gender", this.gender);
      // formData.append("dob", this.dob);
      if (this.image) formData.append("file", this.image as File);
      //formData.append("userId", user._id);
      this.createClient(formData).then((_client) => {
        if (_client) {
          this.$emit("data-saved");
        }
      });
    },
    validateForm() {
      const valid = (
        this.$refs.clientForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;
      this.addClient();
    },
    genPassword(length: number) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    resetForm() {
      this.fullName = "";
      this.phone = "";
      this.dob = "";
      this.email = "";
      this.gender = "";
      this.notes = "";
      this.address = "";
      this.image = undefined;
      (
        this.$refs.clientForm as Element & {
          resetValidation: () => void;
        }
      )?.resetValidation();
    },
    cancel() {
      this.$emit("cancel", true);
    },
    searchLocation(q: string) {
      if (q) {
        const bid = (this.role.business as Business)._id;
        let params = `?businessId=${bid}&q=${q}`;
        this.fetchLocationList(params).then((locationPage) => {
          if (locationPage) {
            this.locations = locationPage.docs.map((l: _Location) => l.name);
          }
        });
      }
    },
    upload(image: File) {
      this.image = image;
    },
    dropHandler(ev: DragEvent) {
      const file = ev.dataTransfer?.files[0] as File;

      this.image = file;
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("CLIENT_FORM")) {
      this.$store.registerModule("CLIENT_FORM", clientStoreModule);
    }
    if (!this.$store.hasModule("CLIENT_LOCATIONS")) {
      this.$store.registerModule("CLIENT_LOCATIONS", locationStoreModule);
    }
    if (!this.$store.hasModule("CLIENT_EMAIL")) {
      this.$store.registerModule("CLIENT_EMAIL", clientEmailStoreModule);
    }
    if (!this.$store.hasModule("USER")) {
      this.$store.registerModule("USER", userStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("CLIENT_FORM");
    this.$store.unregisterModule("CLIENT_LOCATIONS");
    this.$store.unregisterModule("CLIENT_EMAIL");
    this.$store.unregisterModule("USER");
  },
});
