









































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
import moment from "moment-timezone";

import appointmentStoreModule from "@/store/modules/appointment";
import { Business, Role } from "@/types";

const { mapActions: appointmentActions, mapGetters: appointmentGetters } =
  createNamespacedHelpers("CLIENT_APPOINTMENTS");

export default Vue.extend<any, any, any, any>({
  name: "ClientGraph",
  data: () => ({
    date: "Today",
  }),
  created() {
    this.fetchAppointments();
  },
  watch: {
    role: "fetchAppointments",
    date: "fetchAppointments",
  },
  computed: {
    ...appointmentGetters(["appointmentPage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...appointmentActions(["fetchAppointmentList"]),
    fetchAppointments() {
      if (this.role) {
        let period: any = "day";
        switch (this.date) {
          case "This Year":
            period = "year";
            break;
          case "This Month":
            period = "month";
            break;
          case "This Week":
            period = "week";
            break;
          case "Today":
            period = "day";
            break;
          case "All":
            period = "all";
            break;
        }
        const startDate = moment().startOf(period).toISOString();
        const endDate = moment().endOf(period).toISOString();
        const bid = (this.role.business as Business)?._id;
        let params = `?businessId=${bid}&limit=5&status=confirmed`;
        if (period !== "all") {
          params += `&startDate=${startDate}&endDate=${endDate}`;
        }
        this.fetchAppointmentList(params);
      }
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("CLIENT_APPOINTMENTS")) {
      this.$store.registerModule("CLIENT_APPOINTMENTS", appointmentStoreModule);
    }
  },
  beforeDestroy() {
    // this.$store.unregisterModule("CLIENT_APPOINTMENTS");
  },
});
