import { RouteConfig } from "vue-router";

// @ is an alias to /src
import Full from "@/containers/Full.vue";
import Auth from "@/containers/Auth.vue";
import FullLayout from "@/containers/FullLayout.vue";
import Help from "@/containers/Help.vue";
import Landing from "@/containers/Landing.vue";

export const routes: Array<RouteConfig> = [
  {
    path: "/",
    // name: "Dashboard",
    component: Full,
    children: [
      {
        path: "",
        name: "Home",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "@/views/Dashboard.vue"),
      },
    ],
  },
  {
    path: "/calendar",
    name: "Calendar View",
    redirect: "/calendar/",
    component: Full,
    children: [
      {
        path: "",
        name: "Calendar",
        component: () =>
          import(/* webpackChunkName: "calendar" */ "@/views/Calendar.vue"),
      },
    ],
  },
  {
    path: "/staff",
    name: "Staff",
    redirect: "/staff/",
    component: Full,
    children: [
      {
        path: "",
        name: "Staff List",
        component: () =>
          import(/* webpackChunkName: "employees" */ "@/views/Employees.vue"),
      },
      {
        path: ":employeeId",
        name: "Edit Staff",
        props: true,
        component: () =>
          import(/* webpackChunkName: "employee" */ "@/views/Employee.vue"),
      },
    ],
  },
  {
    path: "/checkout",
    name: "Checkout",
    redirect: "/checkout/",
    component: Full,
    children: [
      {
        path: "",
        name: "Checkouts",
        component: () =>
          import(/* webpackChunkName: "checkout" */ "@/views/checkout.vue"),
      },
      {
        path: ":orderId",
        name: "Checkouts",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "checkout" */ "@/views/OrderCheckout.vue"
          ),
      },
    ],
  },
  {
    path: "/client",
    name: "Clients Home",
    redirect: "/client/",
    component: Full,
    children: [
      {
        path: "",
        name: "Clients",
        component: () =>
          import(/* webpackChunkName: "clients" */ "@/views/Clients.vue"),
      },
      {
        path: ":clientId",
        props: true,
        name: "Client",
        component: () =>
          import(/* webpackChunkName: "client" */ "@/views/Client.vue"),
      },
    ],
  },
  {
    path: "/services",
    name: "Service View",
    redirect: "/services/",
    component: Full,
    children: [
      {
        path: "",
        name: "Service",
        component: () =>
          import(/* webpackChunkName: "services" */ "@/views/Services.vue"),
      },
      {
        path: "new",
        name: "Services",
        component: () =>
          import(
            /* webpackChunkName: "service-form" */ "@/views/ServiceForm.vue"
          ),
      },
      {
        path: "single",
        name: "Single Service",
        component: () =>
          import(
            /* webpackChunkName: "service-form" */ "@/views/SingleServiceForm.vue"
          ),
      },
      {
        path: "package",
        name: "Service Package",
        component: () =>
          import(
            /* webpackChunkName: "service-form" */ "@/views/ServicePackageForm.vue"
          ),
      },
      {
        path: ":serviceId",
        name: "Edit Service",
        component: () =>
          import(
            /* webpackChunkName: "update-service-form.vue" */ "@/views/UpdateServiceForm.vue"
          ),
        props: true,
      },
    ],
  },
  {
    path: "/membership",
    name: "Membership List",
    redirect: "/membership/",
    component: Full,
    children: [
      {
        path: "",
        name: "Memberships",
        component: () =>
          import(
            /* webpackChunkName: "memberships" */ "@/views/Memberships.vue"
          ),
      },
      {
        path: "new",
        name: "Add Membership",
        component: () =>
          import(
            /* webpackChunkName: "add-membership" */ "@/views/MembershipForm.vue"
          ),
      },
      {
        path: ":membershipId",
        name: "Edit Membership",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "edit-membership" */ "@/views/EditMembershipForm.vue"
          ),
      },
    ],
  },
  {
    path: "/products",
    name: "Inventory",
    redirect: "/products/",
    component: Full,
    children: [
      {
        path: "",
        name: "Products",
        component: () =>
          import(/* webpackChunkName: "inventory" */ "@/views/Inventory.vue"),
      },
      {
        path: "edit/:productId",
        name: "Edit Product",
        props: true,
        component: () =>
          import(/* webpackChunkName: "inventory" */ "@/views/Product.vue"),
      },
      {
        path: "create",
        name: "New Product",
        props: true,
        component: () =>
          import(/*webpackChunkName: "inventory" */ "@/views/NewProduct.vue"),
      },
    ],
  },
  {
    path: "/appointment-checkout",
    component: Full,
    redirect: "/appointment-checkout/",
    name: "Appointment Checkout",
    children: [
      {
        path: ":appointmentId",
        name: "Checkout ",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "appointment-checkout" */ "@/views/AppointmentCheckout.vue"
          ),
      },
    ],
  },
  {
    path: "/order",
    component: Full,
    redirect: "/order/",
    name: "Orders",
    children: [
      {
        path: ":orderId",
        name: "Order",
        props: true,
        component: () =>
          import(/* webpackChunkName: "Order" */ "@/views/OrderPage.vue"),
      },
    ],
  },
  {
    path: "/payments",
    name: "Payments View",
    redirect: "/payments/",
    component: Full,
    children: [
      {
        path: "",
        name: "Payments",
        component: () =>
          import(/* webpackChunkName: "calendar" */ "@/views/Payments.vue"),
      },
    ],
  },
  {
    path: "/settings",
    name: "settings",
    redirect: "/settings/",
    component: Full,
    children: [
      {
        path: "",
        name: "Settings",
        component: () =>
          import(/* webpackChunkName: "settings" */ "@/views/Settings.vue"),
      },
    ],
  },
  {
    path: "/forms",
    name: "Builder",
    redirect: "/forms/",
    component: Full,
    children: [
      {
        path: "",
        name: "Forms",
        component: () =>
          import(/* webpackChunkName: "form-list" */ "@/views/FormList.vue"),
      },
      {
        path: "builder",
        name: "Form Builder",
        component: () =>
          import(
            /* webpackChunkName: "form-builder" */ "@/views/FormBuilder.vue"
          ),
      },
      {
        path: "edit/:formId",
        name: "Edit Form",
        component: () =>
          import(
            /* webpackChunkName: "form-editor" */ "@/views/FormEditor.vue"
          ),
        props: true,
      },
      {
        path: ":formId",
        name: "Form Preview",
        component: () =>
          import(/* webpackChunkName: "view-form" */ "@/views/ViewForm.vue"),
        props: true,
      },
    ],
  },
  {
    path: "/shop",
    name: "Shop",
    redirect: "/shop/",
    component: Full,
    children: [
      {
        path: "",
        name: "Ahidi Shop",
        component: () =>
          import(/* webpackChunkName: "shop-setup" */ "@/views/Shop.vue"),
      },
      {
        path: "create",
        name: "Ahidi Shop Setup",
        component: () =>
          import(
            /* webpackChunkName: "create-shop" */ "@/views/CreateShop.vue"
          ),
      },
    ],
  },
  {
    path: "/voucher",
    name: "Vouchers",
    redirect: "/vouchers/",
    component: Full,
    children: [
      {
        path: "",
        name: "Voucher",
        component: () =>
          import(/* webpackChunkName: "voucher" */ "@/views/Vouchers.vue"),
      },
      {
        path: "create",
        name: "Create Voucher",
        component: () =>
          import(
            /* webpackChunkName: "voucher-form" */ "@/views/VoucherForm.vue"
          ),
      },
    ],
  },
  {
    path: "/marketing",
    name: "marketing",
    redirect: "/marketing/",
    component: Full,
    children: [
      {
        path: "",
        name: "Marketing",
        component: () =>
          import(/* webpackChunkName: "marketing" */ "@/views/Marketing.vue"),
      },
      {
        path: "ad/create",
        name: "Create Advertisement",
        component: () =>
          import(/* webpackChunkName: "create-ad" */ "@/views/CreateAd.vue"),
      },
    ],
  },
  {
    path: "/client/forms/:formId",
    name: "Client Form",
    component: () =>
      import(
        /* webpackChunkName: "client-consultation-form" */ "@/views/pages/ClientConsultationForm.vue"
      ),
    props: true,
    meta: { noAuth: true },
  },
  {
    path: "/website-builder",
    name: "Site Builder",
    redirect: "/website-builder/",
    component: Full,
    children: [
      {
        path: "",
        name: "Website Builder",
        component: () =>
          import(
            /* webpackChunkName: "website-builder" */ "@/views/WebsiteBuilder.vue"
          ),
      },
      {
        path: ":siteId",
        name: "Website builder",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "website-editor" */ "@/views/WebsiteEditor.vue"
          ),
      },
    ],
  },
  {
    name: "Login",
    path: "/login",
    redirect: "/login/",
    component: Auth,
    children: [
      {
        path: "",
        name: "User Login",
        props: true,
        meta: {
          noAuth: true,
        },
        component: () =>
          import(/* webpackChunkName: "login" */ "@/views/pages/Login.vue"),
      },
    ],
  },
  {
    name: "Forgot Fassword",
    path: "/forgot-password",
    redirect: "/forgot-password/",
    component: Auth,
    children: [
      {
        path: "",
        name: "User Forgot Password",
        props: true,
        meta: {
          noAuth: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "forgot-password" */ "@/views/pages/ForgotPassword.vue"
          ),
      },
    ],
  },
  {
    name: "Full",
    path: "/select-business",
    redirect: "/select-business/",
    component: FullLayout,
    children: [
      {
        path: "/select-business",
        name: "Select Business",
        meta: {
          isAuthorized: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "select-business" */ "@/views/pages/SelectBusiness.vue"
          ),
      },
    ],
  },
  {
    path: "/sign-up",
    name: "Auth",
    redirect: "/sign-up/",
    component: Auth,
    children: [
      {
        name: "Sign Up",
        path: "",
        meta: { noAuth: true },
        component: () =>
          import(/* webpackChunkName: "sign-up" */ "@/views/pages/SignUp.vue"),
      },
      {
        name: "Staff Sign Up",
        path: "staff/:employeeId",
        meta: { noAuth: true },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "employee-sign-up" */ "@/views/pages/EmployeeSignUp.vue"
          ),
      },
      {
        path: "payment/:userId",
        name: "Payment Info",
        meta: { noAuth: true },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "payment-info" */ "@/views/pages/PaymentInfo.vue"
          ),
      },
      {
        path: "business/:userId",
        name: "Business Type",
        meta: { noAuth: true },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "business-type" */ "@/views/pages/BusinessType.vue"
          ),
      },
      {
        path: "plan/:businessId",
        name: "Plan",
        meta: { noAuth: true },
        props: true,
        component: () =>
          import(/* webpackChunkName: "plan" */ "@/views/pages/Plan.vue"),
      },
    ],
  },
  {
    path: "/communications",
    name: "Emails",
    redirect: "/communications/emails/",
    component: Full,
    children: [
      {
        path: "emails",
        name: "Emails Sent to Clients",
        component: () =>
          import(
            /* webpackChunkName: "client-emails" */ "@/components/client/ClientEmails.vue"
          ),
      },
      {
        path: "sms",
        name: "SMS Sent to Clients",
        component: () =>
          import(
            /* webpackChunkName: "client-sms" */ "@/components/client/ClientSMSList.vue"
          ),
      },
    ],
  },
  {
    path: "/reports",
    name: "Reports",
    redirect: "/reports/",
    component: Full,
    children: [
      {
        path: "",
        component: () =>
          import(/* webpackChunkName: "report" */ "@/views/Report.vue"),
      },
    ],
  },
  {
    path: "/feedback",
    name: "Contact Form Submissions",
    redirect: "/feedback/",
    component: Full,
    children: [
      {
        path: "",
        name: "Contact Form Submissions",
        component: () =>
          import(/* webpackChunkName: "feedback" */ "@/views/Feedback.vue"),
      },
    ],
  },
  {
    path: "/pay-deposit",
    name: "Pay Depo",
    redirect: "/pay-deposit/",
    component: Full,
    children: [
      {
        path: "",
        name: "Pay Deposit",
        component: () =>
          import(
            /* webpackChunkName: "pay-deposit" */ "@/views/PayDeposit.vue"
          ),
      },
    ],
  },
  {
    path: "/email-editor",
    name: "Email Editor",
    // redirect: "/email-editor/",
    component: Full,
    children: [
      {
        path: "templates",
        name: "Email Templates",
        component: () =>
          import(
            /* webpackChunkName: "email-template-list" */ "@/views/EmailTemplateList.vue"
          ),
      },
      {
        path: "edit/:templateId",
        name: "Edit Email Template",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "email-template-editor" */ "@/views/EmailTemplateEditor.vue"
          ),
      },
      {
        path: ":templateId",
        name: "Email Template",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "email-editor" */ "@/views/EmailEditor.vue"
          ),
      },
    ],
  },
  {
    path: "/subscribe",
    name: "Subscribe to Plan",
    component: Landing,
    children: [
      {
        path: "",
        name: "Subcribe",
        meta: {
          isAuthorized: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "subacribe" */ "@/views/pages/SubscribePlan.vue"
          ),
      },
    ],
  },
  {
    path: "/getting-started",
    name: "Help",
    redirect: "/getting-started",
    component: Help,
    children: [
      {
        path: "",
        redirect: "modules",
      },
      {
        path: "forum",
        name: "Forum",
        meta: { help: true },
        component: () =>
          import(
            /*webpackChunkName: "Help" */ "@/views/gettingstarted/GettingStarted.vue"
          ),
      },
      {
        path: "modules",
        name: "Getting Started",
        meta: { help: true },
        component: () =>
          import(
            /*webpackChunkName: "Ahidi Modules" */ "@/views/gettingstarted/agizoModules.vue"
          ),
        children: [
          {
            path: "",
            name: "Getting Started",
            meta: { help: true },
            component: () =>
              import(
                /*webpakChunkName: "modules" */ "@/views/gettingstarted/modules/index.vue"
              ),
          },
          {
            path: "analytics",
            name: "Getting Started",
            meta: { help: true },
            component: () =>
              import(
                /*webpakChunkName: "modules" */ "@/views/gettingstarted/modules/analytics.vue"
              ),
          },
          {
            path: "clients",
            name: "Getting Started",
            meta: { help: true },
            component: () =>
              import(
                /*webpakChunkName: "modules" */ "@/views/gettingstarted/modules/clients.vue"
              ),
          },
          {
            path: "staffs",
            name: "Getting Started",
            meta: { help: true },
            component: () =>
              import(
                /*webpakChunkName: "modules" */ "@/views/gettingstarted/modules/staff.vue"
              ),
          },
          {
            path: "inventory",
            name: "Getting Started",
            meta: { help: true },
            component: () =>
              import(
                /*webpakChunkName: "modules" */ "@/views/gettingstarted/modules/inventory.vue"
              ),
          },
          {
            path: "services",
            name: "Getting Started",
            meta: { help: true },
            component: () =>
              import(
                /*webpakChunkName: "modules" */ "@/views/gettingstarted/modules/service.vue"
              ),
          },
          {
            path: "memberships",
            name: "Getting Started",
            meta: { help: true },
            component: () =>
              import(
                /*webpakChunkName: "modules" */ "@/views/gettingstarted/modules/memberships.vue"
              ),
          },
          {
            path: "calendar",
            name: "Getting Started",
            meta: { help: true },
            component: () =>
              import(
                /*webpakChunkName: "modules" */ "@/views/gettingstarted/modules/calendar.vue"
              ),
          },
          {
            path: "shop",
            name: "Getting Started",
            meta: { help: true },
            component: () =>
              import(
                /*webpakChunkName: "modules" */ "@/views/gettingstarted/modules/shop.vue"
              ),
          },
          {
            path: "web-builder",
            name: "Getting Started",
            meta: { help: true },
            component: () =>
              import(
                /*webpakChunkName: "modules" */ "@/views/gettingstarted/modules/websiteBuilder.vue"
              ),
          },
        ],
      },
    ],
  },
  {
    path: "/error-page",
    name: "Error Page",
    component: () =>
      import(/* webpackChunkName: "error-page" */ "@/views/ErrorPage.vue"),
  },
  {
    path: "*",
    name: "Not Found",
    // route level code-splitting
    // this generates a separate chunk (not-found.[hash].js) for this route
    // which is lazy-loaded when the route is ../views/NotFound.vue
    component: () =>
      import(/* webpackChunkName: "not-found" */ "@/views/404Page.vue"),
  },
];
