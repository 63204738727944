export const navLinks = [
  {
    title: "Dashboard",
    icon: "mdi-chart-pie",
    to: "/",
  },
  {
    title: "Staff",
    icon: "mdi-account",
    to: "/staff",
  },
  {
    title: "Calendar",
    icon: "mdi-calendar-blank-multiple",
    to: "/calendar",
  },
  {
    title: "Clients",
    icon: "mdi-account-multiple",
    to: "/client",
  },
  {
    title: "Services",
    icon: "mdi-hammer-wrench",
    to: "/services",
  },
  {
    title: "Inventory",
    icon: "mdi-sale",
    to: "/products",
  },
  {
    title: "Payments",
    icon: "mdi-credit-card-outline",
    to: "/payments",
  },
  {
    title: "Membership",
    icon: "mdi-account-group-outline",
    to: "/membership",
  },
  {
    title: "Form Builder",
    icon: "mdi-store",
    to: "/forms",
  },
  {
    title: "Website Builder",
    icon: "mdi-web",
    to: "/website-builder",
  },
  {
    title: "Marketing",
    icon: "mdi-shopping",
    to: "/marketing",
  },
  {
    title: "Ahidi Shop",
    icon: "mdi-cart-plus",
    to: "/shop",
  },
  {
    title: "More",
    icon: "mdi-menu",
    toggleMore: true,
  },
];

export const moreNavLinks = [
  // {
  //   title: "P.o.S",
  //   icon: "mdi-point-of-sale",
  //   to: "/pos",
  // },
  // {
  //   title: "Tasks",
  //   icon: "mdi-file-tree",
  //   to: "/tasks",
  // },
  // {
  //   title: "Messages",
  //   icon: "mdi-message-text-outline",
  //   to: "/message",
  // },
  // {
  //   title: "Reminder",
  //   icon: "mdi-bell",
  //   to: "/reminder",
  // },
  {
    title: "Reports",
    icon: "mdi-chart-bar",
    to: "/reports",
  },
  {
    title: "Contact Form Submissions",
    icon: "mdi-comment-quote",
    to: "/feedback",
  },
  // {
  //   title: "Online Booking",
  //   icon: "mdi-book",
  //   to: "/online-booking",
  // },
  // {
  //   title: "Loyalty Programs",
  //   icon: "mdi-tag-heart",
  //   to: "/loyalty-programs",
  // },
  // {
  //   title: "Virtual Sessions",
  //   icon: "mdi-virtual-reality",
  //   to: "/virtual-sessions",
  // },
  // {
  //   title: "Chatbot",
  //   icon: "mdi-chat",
  //   to: "/chatbot",
  // },
  // {
  //   title: "Market Place",
  //   icon: "mdi-shopping-outline",
  //   to: "/market-place",
  // },
];

export const bottomNavLinks = [
  {
    title: "Getting Started",
    icon: "mdi-information",
    to: "/getting-started",
  },
  {
    title: "Settings",
    icon: "mdi-cog",
    to: "/settings",
  },
];

export const helpLinks = [
  {
    title: "Modules",
    icon: "mdi-molecule",
    to: "/getting-started/modules",
  },
  {
    title: "Forum",
    icon: "mdi-forum",
    to: "/getting-started/forum",
  },
];

export const bottomHelpLinks = [
  {
    title: "Contact Support",
    icon: "mdi-help",
    to: "/",
  },
  {
    title: "Apps & Intergation",
    icon: "mdi-apps",
    to: "/",
  },
  {
    title: "Keyboard Shortcuts",
    icon: "mdi-keyboard",
    to: "/",
  },
  {
    title: "Desktop App",
    icon: "mdi-desktop-mac",
    to: "/",
  },
];
