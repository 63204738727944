var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"eager":"","width":"500"},model:{value:(_vm.editClientDialog),callback:function ($$v) {_vm.editClientDialog=$$v},expression:"editClientDialog"}},[(_vm.client)?_c('v-card',{staticClass:"px-4 py-4",staticStyle:{"height":"500px"}},[(_vm.client)?_c('div',[_c('div',[_c('b',[_vm._v("Comments")])]),_vm._v(" "+_vm._s(_vm.client.comment || "No comment")+" "),_c('br'),_c('hr'),_c('br'),_c('div',[_c('b',[_vm._v("Product Ratings")])]),_vm._l((_vm.client.productRating),function(productRating){return _c('div',{key:productRating},[_vm._v(" "+_vm._s(productRating.product.title)+" - "+_vm._s(productRating.rating)+" ")])}),_c('br'),_c('hr'),_c('br'),(_vm.client && _vm.client.serviceRating && _vm.client.serviceRating.length)?_c('div',[_c('b',[_vm._v("Service Ratings")])]):_vm._e(),_vm._l((_vm.client.serviceRating),function(serviceRating){return _c('div',{key:serviceRating},[_vm._v(" "+_vm._s(serviceRating.service.name)+" - "+_vm._s(serviceRating.rating)+" ")])})],2):_vm._e()]):_vm._e()],1),_c('v-row',[_c('v-col',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',{staticClass:"filters"})])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.feedbackPage.docs,"options":_vm.options,"server-items-length":_vm.feedbackPage.total,"no-data-text":"No clients to display"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.service",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"flex-start","align-items":"center"}},[_c('span',{staticStyle:{"white-space":"initial","margin-left":"5px"}},[_vm._v(" "+_vm._s(item.service.orderNo)+" ")])])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("readableDate")(item.createdAt,"D/MM/YYYY"))+" "+_vm._s(_vm._f("readableDate")(item.createdAt,"h:mm a"))+" ")]}},{key:"item.clientPhone",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.comment)+" ")]}},{key:"item.rating",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.rating == -1 ? "N/A" : item.rating)+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.rating !== -1)?_c('v-btn',{attrs:{"color":"primary","rounded":""},on:{"click":function($event){_vm.client = item;
          _vm.editClientDialog = true;}}},[_vm._v(" View ")]):_vm._e(),_vm._v("   ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }