










































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import ActionButtons from "@/components/ActionButtons.vue";
import MembershipList from "@/components/membership/MembershipList.vue";
import MembershipProgram from "@/components/membership/MembershipProgramList.vue";
import MembershipSessions from "@/components/membership/MembershipSessions.vue";
import clientMembershipStoreModule from "@/store/modules/clientMembership";
import { Membership } from "@/types";
import AddOnForm from "@/components/AddOnForm.vue";
import vendorPlanStoreModule from "@/store/modules/vendorPlan";
import { Role } from "@/types";
import Advert from "@/views/Advert.vue";
import paystackStoreModule from "@/store/modules/paystack";
import { Business, VendorPlan } from "@/types";
import moment from "moment";

const { mapActions: clientMembershipActions } =
  createNamespacedHelpers("CLIENT_MEMBERSHIP");

const { mapActions: vendorPlanActions } =
  createNamespacedHelpers("VENDOR_PLAN");

const { mapActions: paystackActions } = createNamespacedHelpers(
  "PAYSTACK_SUBSCRIPTION_ADDON"
);

export default Vue.extend<any, any, any, any>({
  name: "Memberships",
  components: {
    MembershipList,
    MembershipProgram,
    ActionButtons,
    MembershipSessions,
    AddOnForm,
    Advert,
  },
  data: () => ({
    membership: undefined as Membership | undefined,
    modal: false,
    advert: true,
    loading: false,
    showInitDialog: false,
    authUrl: "",
    authorizationCode: "",
  }),
  computed: {
    role(): Role {
      return this.$store.getters.role;
    },
    vendorPlan(): VendorPlan {
      return JSON.parse(localStorage.getItem("plan") as string);
    },
    isSubscribed() {
      if (
        !this.$store.getters.role.business.vendorPlan.addOns
          ?.lastMembershipPaymentDate
      ) {
        return false;
      }
      return (
        moment().diff(
          this.$store.getters.role.business.vendorPlan.addOns
            ?.lastMembershipPaymentDate,
          "months"
        ) <= 1
      );
    },
  },
  created() {
    const id = localStorage.getItem("client-membership-id");
    const sessionId = location.search.split("=")[1];
    if (id && sessionId) {
      this.updateClientMembership({
        id,
        clientMembership: { stripeSessionId: sessionId },
      }).then(() => {
        localStorage.removeItem("client-membership-id");
      });
    }
  },
  methods: {
    ...clientMembershipActions(["updateClientMembership"]),
    ...vendorPlanActions(["updateVendorPlan"]),
    ...paystackActions(["initPlanTransaction", "subscribeCustomer"]),
    viewProgram(membership: Membership) {
      this.membership = membership;
    },
    async initSubsription() {
      this.loading = true;
      const roleId = localStorage.getItem("roleId") as string;
      const data = await this.$store.dispatch("getLoggedInUser");
      const vendorPlan: VendorPlan = data?.roles.find(
        (role) => role._id === roleId
      )?.business.vendorPlan;

      if (vendorPlan?.paystackAuthorizationCode) {
        this.authorizationCode = vendorPlan.paystackAuthorizationCode;
        this.subscribe();
        return;
      }
      this.initPlanTransaction({
        //email: (this.role.user as User).email,
        reference: this.vendorPlan._id,
        subscriptionType: "membership",
        amount: 100,
      }).then((url: string) => {
        this.loading = false;
        if (url) {
          this.authUrl = url;
          this.showInitDialog = true;
          this.checkSubscription();
        }
      });
    },

    async checkSubscription() {
      const roleId = localStorage.getItem("roleId") as string;
      const data = await this.$store.dispatch("getLoggedInUser");
      const vendorPlan: VendorPlan = data?.roles.find(
        (role) => role._id === roleId
      )?.business.vendorPlan;

      if (vendorPlan?.paystackAuthorizationCode) {
        this.authorizationCode = vendorPlan.paystackAuthorizationCode;
        this.subscribe();
      } else {
        setTimeout(() => {
          this.checkSubscription();
        }, 2500);
      }
    },
    subscribe() {
      this.loading = true;
      this.subscribeCustomer({
        businessId:
          (this.vendorPlan.business as Business)._id ??
          this.vendorPlan.business,
        planId: this.vendorPlan.plan._id,
        authorizationCode: this.authorizationCode,
        addOn: "membership",
      }).then((subcription) => {
        this.loading = false;
        if (subcription) {
          this.$router.replace((this.$route.query.redirect as string) ?? "/");
        }
      });
    },
    buy() {
      this.initSubsription();
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("CLIENT_MEMBERSHIP")) {
      this.$store.registerModule(
        "CLIENT_MEMBERSHIP",
        clientMembershipStoreModule
      );

      if (!this.$store.hasModule("VENDOR_PLAN")) {
        this.$store.registerModule("VENDOR_PLAN", vendorPlanStoreModule);
      }
      if (!this.$store.hasModule("PAYSTACK_SUBSCRIPTION_ADDON")) {
        this.$store.registerModule(
          "PAYSTACK_SUBSCRIPTION_ADDON",
          paystackStoreModule
        );
      }
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("CLIENT_MEMBERSHIP");
  },
});
