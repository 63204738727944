






















































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
import {
  StockLedgerEntry,
  Warehouse,
  Page,
  Product,
  EmployeePermission,
  Employee,
} from "@/types";
import salesLedgerPageModule from "@/store/modules/salesLedger"; // Import the module
import productInventoryModule from "@/store/modules/productInventory";
import employeeStoreModule from "@/store/modules/employee";
import employeePermissionStoreModule from "@/store/modules/employeePermission";
import EmployeeMiniSearch from "../employee/EmployeeMiniSearch.vue";

const { mapActions } = createNamespacedHelpers("salesLedgerPage");
const {
  mapGetters: inventoryProductGetters,
  mapActions: inventoryProductActions,
} = createNamespacedHelpers("SALES_LEDGER");

export default Vue.extend<any, any, any, any>({
  name: "SalesLedger",
  components: {
    EmployeeMiniSearch,
  },
  data() {
    return {
      loading: false,
      employee: undefined as undefined | Employee | string,
      stockEntry: undefined as StockLedgerEntry | undefined,
      filters: {
        transactionType: null as string | null,
        search: "",
        warehouse: null as string | null,
      },
      transactionTypes: ["SALES", "SALES-RETURNS"],
      warehouseOptions: [] as Array<string>,
      headers: [
        { text: "Customer", value: "customer" },
        { text: "Ordered By", value: "orderedBy" },
        { text: "Transaction", value: "transactionType" },
        { text: "Order No.", value: "order" },

        { text: "Amount", value: "quantity", align: "right" },

        { text: "Date", value: "createdAt" },
        // { text: "Actions", value: "action", sortable: false },
      ],
      options: {} as { page: number; itemsPerPage: number },
      deleteDialog: false,
      addDialog: false,
      newStockEntry: {
        itemCode: "",
        warehouseId: "",
        transactionType: "",
        quantity: null as number | null,
        reference: "",
      },
      warehouseList: [] as Warehouse[],
      productList: [] as Product[],
      rules: {
        required: (value: any) => !!value || "Required.",
        numeric: (value: any) =>
          !isNaN(parseFloat(value)) || "Must be a number.",
      },
    };
  },
  computed: {
    permission(): EmployeePermission {
      return this.$store.getters.permission;
    },
    ...inventoryProductGetters(["productPage"]),
    salesLedgerPage(): Page<StockLedgerEntry> {
      return this.$store.getters["salesLedgerPage/salesLedgerPage"];
    },
    items() {
      return this.productPage?.docs ?? [];
    },
    filteredData(): StockLedgerEntry[] {
      let data = this.salesLedgerPage?.docs || [];

      // Apply transaction type filter
      if (this.filters.transactionType) {
        data = data.filter(
          (item) => item.transactionType === this.filters.transactionType
        );
      }

      // Apply search filter
      if (this.filters.search) {
        const search = this.filters.search.toLowerCase();
        data = data.filter(
          (item) =>
            item.itemCode.title.toLowerCase().includes(search) ||
            (item.reference && item.reference.toLowerCase().includes(search))
        );
      }

      return data;
    },
  },
  methods: {
    ...mapActions(["fetchStockEntries"]),
    ...inventoryProductActions(["fetchProductList"]),

    loadStockEntries() {
      this.loading = true;
      let user = null;
      if (this.employee) {
        user = this.employee.email;
      }

      const params = {
        user,
        page: this.options.page || 1,
        limit: this.options.itemsPerPage || 10,
        sort: "-createdAt",
        businessId: this.$store.getters.role.business._id,
      };

      this.fetchStockEntries(params)
        .then(() => {
          this.loading = false;
          this.loadWarehouseOptions();
        })
        .catch(() => {
          this.loading = false;
        });
    },

    filterByEmployee(e) {
      this.employee = e;
      this.loadStockEntries();
    },
    loadProducts() {
      if (this.$store.getters.role) {
        const params = `?businessId=${this.$store.getters.role.business._id}`;
        this.fetchProductList(params);
      }
    },
    loadWarehouseOptions() {
      this.warehouseOptions = [
        ...new Set(
          this.salesLedgerPage.docs
            .filter((item) => item.warehouse)
            .map((item) => {
              if (item.warehouse && typeof item.warehouse === "object") {
                return item.warehouse.name;
              } else if (typeof item.warehouse === "string") {
                return item.warehouse;
              }
              return "";
            })
            .filter((name) => name) // Remove empty strings
        ),
      ];
    },
    deleteConfirm(item: StockLedgerEntry) {
      this.stockEntry = item;
      this.deleteDialog = true;
    },

    openAddDialog() {
      this.addDialog = true;
      this.loadProducts();
      this.fetchWarehouses({
        page: 1,
        limit: 100,
        businessId: this.$store.getters.role.business._id,
      }).then((response: any) => {
        this.warehouseList = response.docs || [];
      });
    },
  },
  watch: {
    options: {
      handler() {
        this.loadStockEntries();
        this.loadProducts();
      },
      deep: true,
    },

    permission() {
      if (this.permission) {
        this.loadStockEntries();
      }
    },
  },
  beforeCreate() {
    // Register the module if it's not already registered

    if (!this.$store.hasModule("EMPLOYEE_LIST5_PERMISSION")) {
      this.$store.registerModule(
        "EMPLOYEE_LIST5_PERMISSION",
        employeePermissionStoreModule
      );
    }

    if (!this.$store.hasModule("salesLedgerPage")) {
      this.$store.registerModule("salesLedgerPage", salesLedgerPageModule);
    }

    if (!this.$store.hasModule("SALES_LEDGER")) {
      this.$store.registerModule("SALES_LEDGER", productInventoryModule);
    }
  },
  beforeDestroy() {
    // Unregister the module when the component is destroyed

    if (this.$store.hasModule("salesLedgerPage")) {
      this.$store.unregisterModule("salesLedgerPage");
      this.$store.unregisterModule("SALES_LEDGER");
    }

    if (!this.$store.hasModule("SALES_LEDGER")) {
      this.$store.registerModule("SALES_LEDGER", productInventoryModule);
    }
  },
  created() {
    console.log("roles");
    console.log(this.$store.getters.role);
    this.loadStockEntries();
    this.loadProducts();
  },

  mounted() {
    this.loadProducts();
  },
});
