








































































import Vue, { PropType } from "vue";

type Image = {
  file: File;
  data: string | ArrayBuffer | null;
};

const VUE_APP_API_URL = process.env.VUE_APP_API_URL;

export default Vue.extend<any, any, any, any>({
  name: "agizo-uploader",
  props: {
    label: String,
    value: [],
    accept: {
      type: String,
      default: "image/png, image/jpeg, image.jpg",
    },
    helpText: {
      type: String,
    },
    max: {
      type: Number,
      default: 4,
    },
    uploaded: {
      type: [] as PropType<any[]>,
      default: [],
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      images: [] as Image[],
      apiUrl: VUE_APP_API_URL,
    };
  },
  watch: {
    images: {
      handler(val: Image[]) {
        this.$emit(
          "input",
          Object.values(val).map((image: Image) => image.file)
        );
      },
    },
  },
  methods: {
    async handleInput(files: File[]) {
      if (files) {
        try {
          for (let index = 0; index < files.length; index++) {
            const file = files[index];
            const result = await this.validateFile(file);
            console.log(result); // "File is valid."
          }

          this.handleFiles(files);
        } catch (error) {
          alert(error);
          console.error(error); // Validation error message
        }
      }
    },
    validateFile(file) {
      return new Promise((resolve, reject) => {
        const maxSize = 500 * 1024; // 500KB in bytes

        if (file.size > maxSize) {
          reject("File size exceeds 500KB.");
          return;
        }

        const img = new Image();
        const objectUrl = URL.createObjectURL(file);

        img.onload = () => {
          const { width, height } = img;

          if (width !== 700 || height !== 500) {
            reject(
              `Image dimensions must be 700x500px. Current dimensions: ${width}x${height}px.`
            );
          } else {
            resolve("File is valid.");
          }

          URL.revokeObjectURL(objectUrl); // Clean up object URL
        };

        img.onerror = () => {
          reject("Invalid image file.");
          URL.revokeObjectURL(objectUrl);
        };

        img.src = objectUrl; // Start loading the image
      });
    },

    handleFiles(files: File[]) {
      let selectedFiles: Image[] = this.images;
      [...files].forEach((file) => {
        let reader = new FileReader();
        reader.onload = (e) => {
          if (e.target) {
            selectedFiles.push({ file, data: e.target.result });
          }
        };
        reader.readAsDataURL(file);
      });

      this.images = selectedFiles;
    },
    removeImage(index: number) {
      this.images.splice(index, 1);
      this.$emit(
        "input",
        this.images.map((image) => image.file)
      );
    },
  },
});
