







































































































































































































































































































































































































































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import payoutStoreModule from "@/store/modules/payout/payout";
import PaymentGatewayStoreModule from "@/store/modules/paymentVendorGateway";
import addStripeVendorForm from "../payment/addStripeVendorForm.vue";
import addMpesaVendorForm from "../payment/addMpesaVendorForm.vue";
import BankPayout from "@/components/payouts/bankPayout.vue";
import PayoutList from "./PayoutList.vue";
import { Business, Order, Role, User } from "@/types";
import { sendOtp, verifyOtp } from "@/util/otp";
import PaystackVendorForm from "../payment/PaystackVendorForm.vue";
import PaystackSubaccountForm from "./PaystackSubaccountForm.vue";
import PaystackPayoutList from "./PaystackPayoutList .vue";
import paystackStoreModule from "@/store/modules/paystack";

const { mapActions: payoutActions, mapGetters: payoutGetters } =
  createNamespacedHelpers("PAYOUT");

const { mapActions: gatewayActions, mapGetters: gatewayGatters } =
  createNamespacedHelpers("GATEWAY");

const namesapce = "PAYSTACK_PAYOUT";
const { mapActions: paystackActions, mapGetters: paystackGetters } =
  createNamespacedHelpers(namesapce);

interface Wallet {
  currency: Order["payment"]["currency"];
  payments: { mpesa: number; card: number; cash: number };
  payouts: { mpesa: number; card: number; bank: number };
  totalPayments: number;
  totalPayouts: number;
  balance: number;
}

export default Vue.extend({
  components: {
    addStripeVendorForm,
    PayoutList,
    addMpesaVendorForm,
    BankPayout,
    PaystackVendorForm,
    PaystackSubaccountForm,
    PaystackPayoutList,
  },
  name: "Payout",
  props: {
    mode: {
      type: String,
    },
  },
  data: () => ({
    loading: true,
    processing: false,
    showOnboardingForm: false,
    showPayoutConfigDialog: false,
    showBankPayoutConfigDialog: false,
    sendingOtp: false,
    interval: "",
    monthly_anchor: null,
    weekly_anchor: "",
    delay_days: null,
    payoutAmount: 0,
    payoutMode: "m-pesa",
    otp: null,
    showPayoutDialog: false,
    showPayoutConfirmDialog: false,
    amountRules: [(v: string) => !!v || "Amount field is required"],
    codeRules: [(v: string) => !!v || "Confirmation code is required"],
    monthlyAnchorList: [
      { text: "1st", value: 1 },
      { text: "2nd", value: 2 },
      { text: "3rd", value: 3 },
      { text: "4th", value: 4 },
      { text: "5th", value: 5 },
      { text: "6th", value: 6 },
      { text: "7th", value: 7 },
      { text: "8th", value: 8 },
      { text: "9th", value: 9 },
      { text: "10th", value: 10 },
      { text: "11th", value: 11 },
      { text: "12th", value: 12 },
      { text: "13th", value: 13 },
      { text: "14th", value: 14 },
      { text: "15th", value: 15 },
      { text: "16th", value: 16 },
      { text: "17th", value: 17 },
      { text: "18th", value: 18 },
      { text: "18th", value: 19 },
      { text: "20th", value: 20 },
      { text: "21st", value: 21 },
      { text: "22nd", value: 22 },
      { text: "23rd", value: 23 },
      { text: "24th", value: 24 },
      { text: "25th", value: 25 },
      { text: "26th", value: 26 },
      { text: "27th", value: 27 },
      { text: "28th", value: 28 },
      { text: "29th", value: 29 },
      { text: "30th", value: 30 },
      { text: "31st", value: 31 },
    ],
    reload: false,
    confirmToken: "",
    showPaystackOnboardingForm: false,
    subaccountDialog: false,
    transferRecipientModal: false,
    paystackPayoutModal: false,
    paysatckPayoutAmount: null,
    confirmPaystackPayoutModal: false,
    confirmCode: null,
  }),
  computed: {
    ...payoutGetters(["payoutPage", "stripeBalance", "mpesaGateway"]),
    ...gatewayGatters(["gateways"]),
    role(): Role {
      return this.$store.getters.role;
    },
    payoutFee(): any {
      if (this.mode == "mpesa") {
        return `Transaction Fee: kes ${this.payoutAmount >= 1000 ? 27 : 15}`;
      }
      return `Transaction Fee: kes ${0}`;
    },
    isPaystackSetUp(): boolean {
      return !!this.gateways.gateways.paystackTransferRecipient;
    },
    isMpesaSetup(): boolean {
      return this.gateways.isMpesaSetup || false;
    },
    mpesaGatewayData(): any {
      return this.gateways.gateways.mpesaGateway;
    },
    user(): User {
      return this.$store.getters.user;
    },
    wallet(): Wallet {
      return this.$store.getters["PAYOUT/wallet"];
    },
  },
  watch: {
    role: "fetchData",
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...payoutActions([
      "updatePayoutConfig",
      "fetchStripeBalance",
      "createStripePayout",
      "createMpesaPayout",
      "fetchWallet",
      "createBankPayout",
      "sendBankTransferConfirmCode",
    ]),
    ...gatewayActions(["fetchPaymentGateways"]),
    ...paystackActions(["createTransfer"]),
    fetchData() {
      if (this.role) {
        const bid = (this.role.business as Business)._id;
        // this.fetchStripeBalance(bid);
        this.fetchWallet(bid);
        this.fetchPaymentGateways(bid).then(() => {
          this.loading = false;
        });
      }
    },
    validatePayoutConfig() {
      const valid = (
        this.$refs.configForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;

      if (!this.role) return;
      this.processing = true;
      const vendor = (this.role.business as Business)._id;

      const data: {
        interval?: string;
        delay_days?: null;
        weekly_anchor?: string;
        monthly_anchor?: null;
      } = {};

      if (this.interval) data.interval = this.interval.toLowerCase();
      if (this.weekly_anchor) data.weekly_anchor = this.weekly_anchor;
      if (this.monthly_anchor) data.monthly_anchor = this.monthly_anchor;
      if (this.delay_days) data.delay_days = this.delay_days;

      this.updatePayoutConfig({
        vendor,
        data,
      })
        .then(() => {
          this.showPayoutConfigDialog = false;
        })
        .finally(() => (this.processing = false));
    },
    handleDataSaved(result: any) {
      if (result.success) {
        this.showOnboardingForm = false;
        location.href = result.url;
      }
      this.$notify({
        group: "alerts",
        title: "Request failed",
        text: "Could not create your connected stripe account",
        type: "error",
      });
    },
    configurePayout() {
      this.showPayoutConfigDialog = true;
    },
    configureBankPayout() {
      this.showBankPayoutConfigDialog = true;
    },
    doMpesaPayout() {
      if (this.mpesaGatewayData) {
        const { mpesa_paybill_number, phoneNumber: phone } =
          this.mpesaGatewayData;
        this.createMpesaPayout({
          vendorId: (this.role.business as Business)._id,
          amount: this.payoutAmount,
          currency: "KES",
          method: "m-pesa",
          phoneNumber: mpesa_paybill_number || phone,
        })
          .then(() => {
            this.payoutAmount = 0;
            this.showPayoutDialog = false;
            this.fetchWallet((this.role.business as Business)._id);
            this.reload = true;
          })
          .finally(() => (this.processing = false));
      }
    },
    sendOtpToPhone() {
      if (this.mpesaGatewayData && this.mpesaGatewayData.phoneNumber) {
        this.sendingOtp = true;
        sendOtp(`${this.mpesaGatewayData.phoneNumber}`)
          .then(({ data }) => {
            this.confirmToken = data.token;
            this.$store.dispatch(
              "setToast",
              {
                title: "OTP success!",
                type: "success",
                text: "Check your phone for the OTP",
              },
              { root: true }
            );
          })
          .catch((error) => {
            this.$store.dispatch(
              "setToast",
              {
                title: "Request failed!",
                type: "error",
                text: error.response?.data?.error?.message,
              },
              { root: true }
            );
          })
          .finally(() => (this.sendingOtp = false));
      }
    },
    cancel() {
      console.log("cencel");
      this.showPaystackOnboardingForm = false;
    },
    doPaystackPayout() {
      this.createTransfer({
        businessId: this.role.business._id,
        amount: this.paysatckPayoutAmount,
        confirmationCode: this.confirmCode,
      }).then((val) => {
        this.paystackPayoutModal = false;
      });
    },
    requestConfirmation() {
      this.sendBankTransferConfirmCode({
        businessId: this.role.business._id,
      }).then((val) => {
        if (val) {
          this.paystackPayoutModal = false;
          this.confirmPaystackPayoutModal = true;
        }
      });
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("PAYOUT")) {
      this.$store.registerModule("PAYOUT", payoutStoreModule);
    }
    if (!this.$store.hasModule("GATEWAY")) {
      this.$store.registerModule("GATEWAY", PaymentGatewayStoreModule);
    }
    if (!this.$store.hasModule(namesapce)) {
      this.$store.registerModule(namesapce, paystackStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("PAYOUT");
    this.$store.unregisterModule("GATEWAY");
    this.$store.unregisterModule(namesapce);
  },
});
