




















































import Vue from "vue";
import { api } from "@/util/axios";
import EmployeeMiniSearch from "../employee/EmployeeSearch.vue";
import employee from "@/store/modules/employee";
import { watch } from "vue";
export default Vue.extend<any, any, any, any>({
  name: "EmployeeReportList",
  components: {
    EmployeeMiniSearch,
  },
  data: () => ({
    headers: [
      { text: "Type", value: "name", sortable: false },
      { text: "Sales", value: "value", sortable: false },
      { text: "actions", value: "actions", sortable: false },
    ],
    reports: [
      {
        name: "Staff Revenue",
        value: 0,
      },
      {
        name: "Staff attendance Rate",
        value: "N/A",
      },
    ],
    startDate: null,
    endDate: null,
    employee: null,
  }),
  watch: {
    startDate() {
      this.fetchSalesReports();
    },
    endDate() {
      this.fetchSalesReports();
    },
    employee() {
      this.fetchSalesReports();
    },
  },
  methods: {
    fetchSalesReports() {
      const businessId = this.$store.getters.role.business._id;
      let apiUrl = `/v1/sales-ledger/staff-revenue?businessId=${businessId}`;

      if (this.employee) {
        apiUrl += `&staff=${this.employee.eId}`;
      }

      if (this.startDate) {
        const date = new Date(this.startDate);
        date.setHours(0, 0, 0, 0);
        apiUrl += `&startDate=${date.toISOString()}`;
      }

      if (this.endDate) {
        const date = new Date(this.endDate);
        date.setHours(23, 59, 59, 59);
        apiUrl += `&endDate=${date.toISOString()}`;
      }

      api.get(apiUrl).then((response) => {
        this.reports[0].value = response.data.reports;
      });
    },
    clear() {
      this.startDate = null;
      this.endDate = null;
      this.employee = null;
    },

    navigate(item) {
      let typex = "service&paymentStatus=completed";
      if (this.employee) {
        typex += `&staff=${this.employee.eId}`;
      }
      const query: any = {
        tab: "orders",
        type: typex,
      };
      if (this.startDate) {
        const date = new Date(this.startDate);
        date.setHours(0, 0, 0, 0);
        query.startDate = date.toISOString();
      }
      if (this.endDate) {
        const date = new Date(this.endDate);
        date.setHours(23, 59, 59, 59);
        query.endDate = date.toISOString();
      }
      this.$router.push({
        path: "/products",
        query,
      });
    },
    filterByEmployee(em) {
      this.employee = em;
    },
  },
  mounted() {
    console.log("mounted");
    this.fetchSalesReports();
  },
});
