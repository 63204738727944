

































































































































































































































































































































































































































































































































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
import moment from "moment-timezone";
const tz = localStorage.getItem("tz") ?? "Africa/Nairobi";

import EditAppointmentForm from "@/components/AppointmentDetails.vue";
import AppointmentList from "@/components/client/AppointmentList.vue";
import appointmentStoreModule from "@/store/modules/appointment";
import employeeStoreModule from "@/store/modules/employee";
import {
  Business,
  Client,
  Employee,
  EmployeePermission,
  Role,
  Service,
} from "@/types";
import ActionButtons from "@/components/ActionButtons.vue";
import AppointmentForm from "@/components/AppointmentForm.vue";
import EmployeeListSearch from "@/components/employee/EmployeeListSearch.vue";
import employee from "@/store/modules/employee";

const { mapActions: employeeActions, mapGetters: employeeGetters } =
  createNamespacedHelpers("CALENDAR_EMPLOYEE_LIST");

const { mapActions: appointmentActions, mapGetters: appointmentGetters } =
  createNamespacedHelpers("CALENDAR_APPOINTMENTS");

const API_URL = process.env.VUE_APP_API_URL;

type CalendarEvent = {
  name: string;
  start: string;
  end: string;
  color: string;
  textColor: string;
  timed: boolean;
  category: string;
  data: {
    client: Client;
    employee: Employee;
    services: Service[];
    price: number;
  };
};

type Category = Employee & { index: number };

const formatDate = (date: string | Date, format?: string) => {
  if (!date) return null;

  if (format) return moment(date).tz(tz).format(format);

  return moment(date).tz(tz).format("DD/MM/YYYY");
};

const shortenString = (str: string, maxLen: number) => {
  if (str.length <= maxLen) return str;
  return str.substring(0, maxLen) + "...";
};

export default Vue.extend<any, any, any, any>({
  name: "Calendar",
  components: {
    EditAppointmentForm,
    ActionButtons,
    AppointmentForm,
    AppointmentList,
    EmployeeListSearch,
  },
  data: () => ({
    filtertEmployeeDialog: false,
    selectedStatus: null,
    selectedPaymentStatus: null,
    focus: "",
    type: "month",
    selectedEmployee: undefined as undefined | Employee,
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    showList: false,
    start: { date: null, day: null, year: null },
    end: { date: null, day: null, year: null },
    showRescheduleDialog: false,
    rescheduleFormValid: false,
    showCancelDialog: false,
    date: "",
    time: "",
    dateRules: [(v: string) => !!v || "Date is required"],
    timeRules: [(v: string) => !!v || "Time is required"],
    events: [] as CalendarEvent[],
    colors: [
      "#D3D6F8",
      "#F5D2D0",
      "#D3F9DB",
      "#F0D4FE",
      "#F5CFE6",
      // from https://material.io/resources/color/
      "#f05545",
      "#bc477b",
      "#7c43bd",
      "#428e92",
      "#b4a647",
      "#ff833a",
      "#f9683a",
      "#6a4f4b",
      "#4f5b62",
    ],
    textColors: [
      "#3545E2",
      "#F21501",
      "#48E153",
      "#CB69FA",
      "#DB53A4",
      // from https://material.io/resources/color/
      "#b71c1c",
      "#880e4f",
      "#4a148c",
      "#006064",
      "#827717",
      "#e65100",
      "#bf360c",
      "#3e2723",
      "#263238",
    ],
    categories: [] as Category[],
    currency: "KES",
    addAppointmentDialog: false,
    apiUrl: API_URL,
    basicViewEmployeeId: "",
    currentRangeType: "month", // Default range type
    currentDate: moment(),
    startDate: moment().startOf("month"),
    endDate: moment().endOf("month"),
    displayRange: "",
    selectedEmployees: [] as Employee[],
  }),
  computed: {
    ...appointmentGetters(["appointmentPage"]),
    ...employeeGetters(["employeePage"]),
    permission(): EmployeePermission {
      return this.$store.getters.permission;
    },
    dateRange() {
      let { start, end } = this;

      // If start or end is not provided, default to the first and last day of the current month
      if (!start || !end) {
        const now = new Date();
        const currentYear = now.getFullYear();
        const currentMonth = now.getMonth(); // Months are 0-based in JavaScript Date objects
        const firstDay = 1;
        const lastDay = new Date(currentYear, currentMonth + 1, 0).getDate(); // Last day of the current month

        if (!start) {
          start = {
            year: currentYear,
            month: currentMonth + 1, // Adjust to 1-based indexing
            day: firstDay,
          };
        }
        if (!end) {
          end = {
            year: currentYear,
            month: currentMonth + 1,
            day: lastDay,
          };
        }
      }

      const startDateObj = new Date(start.year, start.month - 1, start.day);
      const endDateObj = new Date(end.year, end.month - 1, end.day);
      const startDateISO = startDateObj.toISOString().split("T")[0];
      const endDateISO = endDateObj.toISOString().split("T")[0];

      const startMonth = this.monthFormatter(start);
      const endMonth = this.monthFormatter(end);
      const startYear = start.year;
      const endYear = end.year;
      const startDay = start.day + this.nth(start.day);
      const endDay = end.day + this.nth(end.day);

      let title = "";
      switch (this.type) {
        case "month":
          title = `${startMonth} ${startYear}`;
          break;
        case "week":
          title = `${startMonth} ${startDay} ${startYear} - ${endMonth} ${endDay} ${endYear}`;
          break;
        case "day":
        case "category":
          title = `${startMonth} ${startDay} ${startYear}`;
          break;
        default:
          title = "";
      }

      return {
        isoDate: startDateISO,
        startDate: startDateObj.toISOString(),
        endDate: endDateObj.toISOString(),
        title,
      };
    },

    title(): string {
      const { start, end } = this;
      if (!start || !end) {
        return "";
      }
      const startMonth = this.monthFormatter(start);
      const endMonth = this.monthFormatter(end);
      const suffixMonth = endMonth;
      const startYear = start.year;
      const endYear = end.year;
      const suffixYear = endYear;
      const startDay = start.day + this.nth(start.day);
      const endDay = end.day + this.nth(end.day);
      switch (this.type) {
        case "month":
          return `${startMonth} ${startYear}`;
        case "week":
          return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`;
        case "day":
        case "category":
          return `${startMonth} ${startDay} ${startYear}`;
      }
      return "";
    },
    employeeId() {
      return this.selectedEmployee ? this.selectedEmployee?._id : null;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    monthFormatter(): any {
      return (
        this.$refs.calendar as Element & {
          getFormatter: (d: { timeZone: string; month: string }) => string;
        }
      ).getFormatter({
        timeZone: "UTC",
        month: "short",
      });
    },
    getEventHeight(): number {
      if (this.type === "category") return 100;
      return 40;
    },
    role(): Role {
      return this.$store.getters.role;
    },
    permitted(): boolean {
      if (!this.$store.getters.permission) return false;
      return !!this.$store.getters.permission.permissionGroup.permissions.find(
        (p) => {
          if (p.section == "employees") {
            return !!p.acl.find(
              (acl) => acl.name == "All Employees" && !acl.basic
            );
          }
          return false;
        }
      );
    },
    employeeList(): Employee[] {
      const employee = this.employeePage.docs.find((emp: Employee) => {
        return emp.user?._id === this.role?.user?._id;
      });
      return this.employeePage.docs.map((e: Employee) => ({
        ...e,
        ...{
          disabled: !employee || (!this.permitted && e._id !== employee._id),
        },
      }));
    },
  },
  filters: {
    formatDate(date: string, format: string) {
      return formatDate(date, format);
    },
  },
  watch: {
    showList(v) {
      if (!v) {
        // this.syncObjectsToDates();
        this.selectEmployee();
      }
    },
    type(v) {
      if (v == "month") {
        this.currentRangeType = "month";
      } else if (v == "week") {
        this.currentRangeType = "week";
      } else {
        this.currentRangeType = "day";
      }
      this.updateDateRange();
    },
    selectedStatus() {
      this.getAppointments("");
    },
    selectedPaymentStatus() {
      this.getAppointments("");
    },
    permission() {
      if (this.permission) {
        const permitted = !!this.permission.permissionGroup.permissions.find(
          (p) => {
            if (p.section == "employees") {
              return !!p.acl.find(
                (acl) => acl.name == "All Employees" && !acl.basic
              );
            }
            return false;
          }
        );

        if (permitted) {
          const bid = this.permission.employee?.business._id;
          this.fetchEmployeeList(`?businessId=${bid}`).then(() => {
            const params = `?businessId=${bid}`;
            this.getAppointments(params);
          });
        } else {
          this.basicViewEmployeeId = this.permission.employee._id;
          this.fetchEmployee(
            `?employeeId=${this.permission.employee?._id}`
          ).then((emp) => {
            if (emp) {
              const params = `?employeeId=${this.permission.employee._id}`;
              this.getAppointments(params);
            }
          });
        }
      }
    },
    selectedEmployees() {
      if (this.permission) {
        if (this.selectedEmployees.length) {
          this.categories = this.selectedEmployees.map((e, i) => ({
            ...e,
            ...{ index: i },
          }));
          const eid = this.selectedEmployees
            .map((e) => e._id)
            .join("&employeeId=");
          this.fetchAppointmentList(`?employeeId=${eid}&limit=10000`).then(
            (page) => {
              if (page) this.setEvents();
            }
          );
        }
      }
    },
  },
  mounted() {
    (
      this.$refs.calendar as Element & { checkChange: () => void }
    )?.checkChange();
    this.type = "month";

    this.updateDateRange();
    this.selectEmployee();
  },
  methods: {
    ...appointmentActions(["fetchAppointmentList"]),
    ...employeeActions(["fetchEmployeeList", "fetchEmployee"]),
    viewDay(d: { date: string }): void {
      this.focus = d.date;
      this.type = "category";
    },
    getEventColor(event: { color: string }): string {
      return event.color;
    },
    setToday(): void {
      this.focus = "";
    },
    prev(): void {
      (this.$refs.calendar as Element & { prev: () => void }).prev();
    },
    next(): void {
      (this.$refs.calendar as Element & { next: () => void }).next();
    },
    showEvent(d: { nativeEvent: Event; event: Event }): void {
      const open = () => {
        this.selectedEvent = d.event;
        this.selectedElement = d.nativeEvent.target as null;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      d.nativeEvent.stopPropagation();
    },
    updateRange(d: { start: { date: Date }; end: { date: Date } }): void {
      this.start = d.start as unknown as { date: null; year: null; day: null };
      this.end = d.end as unknown as { date: null; year: null; day: null };
      this.setEvents();
    },
    setEvents(): void {
      const events: CalendarEvent[] = [];

      for (let i = 0; i < this.appointmentPage.docs.length; i++) {
        const appointment = this.appointmentPage.docs[i];
        // if (appointment.status === "cancelled") continue;
        const end = moment(appointment.startDate)
          .add(appointment.durationInMinutes, "m")
          .toDate();

        let employeeIndx = this.employeePage.docs.findIndex(
          (e: Employee) => e._id === appointment.employee?._id
        );

        events.push({
          name: shortenString(appointment.employee?.fullName?.split(" ")[0], 5),
          start: moment(appointment.startDate)
            //.tz(tz)
            .format("YYYY-MM-DD HH:mm"),
          end: moment(end).format("YYYY-MM-DD HH:mm"),
          color: this.colors[employeeIndx],
          textColor: this.textColors[employeeIndx],
          timed: false,
          category: appointment.employee?.fullName,
          data: appointment,
        });
      }

      // if (this.type !== "category") {
      //   const _events: {
      //     [key: string]: CalendarEvent & { slots?: string[] };
      //   } = {};

      //   events.map((e) => {
      //     const start = new Date(e.start);
      //     const end = new Date(e.end);
      //     if (_events[e.name]) {
      //       _events[e.name].slots?.push(
      //         `${start.getHours()}:${start.getMinutes()}-${end.getHours()}:${end.getMinutes()}`
      //       );
      //     } else {
      //       _events[e.name] = e;
      //       _events[e.name].slots = [
      //         `${start.getHours()}:${start.getMinutes()}-${end.getHours()}:${end.getMinutes()}`,
      //       ];
      //     }
      //   });

      //   this.events = Object.values(_events);
      //   return;
      // }

      this.events = events;
    },
    nth(d: number | null) {
      return (d as number) > 3 && (d as number) < 21
        ? "th"
        : ["th", "st", "nd", "rd", "th", "th", "th", "th", "th", "th"][
            (d as number) % 10
          ];
    },
    validateRescheduleForm(): void {
      const valid = (
        this.$refs.rescheduleForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;
      this.showRescheduleDialog = false;
      // Logic for updating appoinment
    },
    resetRescheduleForm(): void {
      this.date = "";
      this.time = "";
      (
        this.$refs.rescheduleForm as Element & {
          resetValidation: () => void;
        }
      )?.resetValidation();
    },
    getAppointments(params: string) {
      const categories: Category[] = this.employeePage.docs.map(
        (e: Employee, i: number) => ({ ...e, ...{ index: i } })
      );

      this.categories = categories; //.filter(
      //   (item, i, ar) => ar.indexOf(item) === i
      // );

      if (this.selectedStatus && this.selectedStatus != "All Appointments") {
        params += params.length
          ? `&status=${this.selectedStatus.toLowerCase()}`
          : `?status=${this.selectedStatus.toLowerCase()}`;
      }

      if (
        this.selectedPaymentStatus &&
        this.selectedPaymentStatus != "All Payments"
      ) {
        params += params.length
          ? `&paymentStatus=${this.selectedPaymentStatus.toLowerCase()}`
          : `?paymentStatus=${this.selectedPaymentStatus.toLowerCase()}`;
      }

      if (this.selectedEmployee) {
        params += params.length
          ? `&employeeId=${this.selectedEmployee._id}`
          : `?employeeId=${this.selectedEmployee._id}`;
      }
      const range = this.dateRange;

      if (range.startDate && range.endDate) {
        params += params.length
          ? `&startDate=${range.startDate}&endDate=${range.endDate}&limit=10000`
          : `?startDate=${range.startDate}&endDate=${range.endDate}&limit=10000`;
      }

      this.fetchAppointmentList(params).then((page) => {
        if (page) this.setEvents();
      });
    },
    selectEmployee(employee?: Employee) {
      if (!employee) {
        this.selectedEmployee = null;
      }
      if (employee) {
        this.selectedEmployee = employee;
        this.categories = [{ ...employee, ...{ index: 0 } }];
        const eid = employee._id;
        this.fetchAppointmentList(`?employeeId=${eid}&limit=10000`).then(
          (page) => {
            if (page) this.setEvents();
          }
        );
      } else {
        const bid = (this.role.business as Business)._id;
        let params = `?businessId=${bid}`;
        if (this.basicViewEmployeeId) {
          params = `?employeeId=${this.basicViewEmployeeId}&limit=10000`;
        }
        this.getAppointments(params);
      }
    },
    moveHandler(ev: { date: string }) {
      let startDate = "";
      let endDate = "";
      if (this.type === "category") {
        startDate = moment(ev.date).startOf("day").toISOString();
        endDate = moment(ev.date).endOf("day").toISOString();
      } else if (this.type === "week") {
        startDate = moment(ev.date).startOf("week").toISOString();
        endDate = moment(ev.date).endOf("week").toISOString();
      } else if (this.type === "month") {
        startDate = moment(ev.date).startOf("month").toISOString();
        endDate = moment(ev.date).endOf("month").toISOString();
      }
      const bid = (this.permission.employee.business as Business)._id;
      const params = `?businessId=${bid}&startDate=${startDate}&endDate=${endDate}&limit=10000`;
      this.fetchAppointmentList(params).then((page) => {
        if (page) {
          this.setEvents();
        }
      });
    },
    onTime(args: { date: string; time: string }) {
      this.time = args.time;
      this.date = args.date;
      this.addAppointmentDialog = true;
    },
    closeModal() {
      this.addAppointmentDialog = false;
      const bid = (this.permission.employee.business as Business)._id;
      let params = `?businessId=${bid}`;
      if (this.basicViewEmployeeId) {
        params = `?employeeId=${this.basicViewEmployeeId}`;
      }
      this.getAppointments(params);
    },
    onDay(args: { date: string }) {
      this.date = args.date;
      this.addAppointmentDialog = true;
    },
    getImage(filename: string) {
      return `${this.apiUrl}/v1/file/${filename}`;
    },
    updateDateRange() {
      switch (this.currentRangeType) {
        case "month":
          this.startDate = this.currentDate.clone().startOf("month");
          this.endDate = this.currentDate.clone().endOf("month");
          // Only show the month name and year for the month view
          this.displayRange = this.startDate.format("MMMM YYYY");
          break;
        case "week":
          this.startDate = this.currentDate.clone().startOf("isoWeek");
          this.endDate = this.currentDate.clone().endOf("isoWeek");
          this.displayRange = `${this.startDate.format(
            "MMMM Do YYYY"
          )} - ${this.endDate.format("MMMM Do YYYY")}`;
          break;
        case "day":
          this.startDate = this.currentDate.clone().startOf("day");
          this.endDate = this.currentDate.clone().endOf("day");
          this.displayRange = this.startDate.format("MMMM Do YYYY");
          break;
      }
      this.syncDatesToObjects();
      this.emitDateRange();
    },
    onRangeTypeChange(type) {
      this.currentRangeType = type;
      this.updateDateRange();
    },
    navigateNext() {
      switch (this.currentRangeType) {
        case "month":
          this.currentDate.add(1, "month");
          break;
        case "week":
          this.currentDate.add(1, "week");
          break;
        case "day":
          this.currentDate.add(1, "day");
          break;
      }
      this.updateDateRange();
    },
    navigatePrevious() {
      switch (this.currentRangeType) {
        case "month":
          this.currentDate.subtract(1, "month");
          break;
        case "week":
          this.currentDate.subtract(1, "week");
          break;
        case "day":
          this.currentDate.subtract(1, "day");
          break;
      }
      this.updateDateRange();
    },
    emitDateRange() {
      this.$emit("rangeChange", {
        start: this.startDate.toISOString(),
        end: this.endDate.toISOString(),
      });
    },
    syncDatesToObjects() {
      // Sync startDate with start object
      if (this.startDate) {
        this.start.date = this.startDate.format("YYYY-MM-DD");
        this.start.day = this.startDate.date(); // Day of the month
        this.start.year = this.startDate.year();
        // Sync endDate with end object
        this.end.date = this.endDate.format("YYYY-MM-DD");
        this.end.day = this.endDate.date();
        this.end.year = this.endDate.year();
      }
    },
    syncObjectsToDates() {
      // Sync start object to startDate

      if (this.start.date) {
        this.startDate = moment(
          `${this.start.year}-${this.start.date}`,
          "YYYY-MM-DD"
        ).startOf("day");

        // Sync end object to endDate
        this.endDate = moment(
          `${this.end.year}-${this.end.date}`,
          "YYYY-MM-DD"
        ).endOf("day");
      }
    },
    searchEmployees(q: string) {
      const bid = (this.role.business as Business)._id;
      let params = `?businessId=${bid}`;
      if (q) {
        params += `&q=${q}`;
      }
      this.fetchEmployeeList(params);
    },
  },

  beforeCreate() {
    if (!this.$store.hasModule("CALENDAR_APPOINTMENTS")) {
      this.$store.registerModule(
        "CALENDAR_APPOINTMENTS",
        appointmentStoreModule
      );
    }
    if (!this.$store.hasModule("CALENDAR_EMPLOYEE_LIST")) {
      this.$store.registerModule("CALENDAR_EMPLOYEE_LIST", employeeStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("CALENDAR_APPOINTMENTS");
    this.$store.unregisterModule("CALENDAR_EMPLOYEE_LIST");
  },
});
